@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,400;1,700&family=Rowdies:wght@300;400;700&&family=Rubik:ital,wght@0,300..900;1,300..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import './assets/styles/variables/breakpoints';
@import './assets/styles/variables/variables';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;

  /* 1rem = 10px the html fontsize determines the value of one rem */

  // @media only screen and (max-width: $bp-1300) {
  //   font-size: 56.25%; //1rem becomes 9px.
  // }

  @media only screen and (max-width: $bp-1000) {
    font-size: 56.25%; //1rem becomes 9px.
  }

  @media only screen and (max-width: $bp-360) {
    font-size: 50%; //1rem becomes 8px.
  }

  // @media only screen and (max-width: $bp-360) {
  //   font-size: 37.5%; //1rem becomes 6px.
  // }
}

body {
  background-color: var(--color-white);
  background-size: cover;
  background-repeat: no-repeat;
  font-family: var(--primary-font);
  font-weight: 400;
  font-size: var(--default-font-small);
  line-height: 2.2rem;
  color: var(--color-dark);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;

  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .action-text {
    @apply underline cursor-pointer text-[#1f68d6] font-bold;
  }
}