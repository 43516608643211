@import "../variables/breakpoints";

.ql {
  width: 100%;
  border-radius: 2rem;
  background-color: var(--color-fash-primary-4);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 3.5rem 2rem;
  @media only screen and (max-width: $bp-600) {
    grid-column: 1/2;
    grid-row: 2/3;
    width: 95%;
    justify-self: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    gap: 2rem;
    margin-top: 3rem;
  }
}
