// styles for BusinessInsightCards and QuickCards//
@import "../variables/breakpoints";

.main {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 2rem;

  // @media only screen and (max-width: $bp-1000) {
  //   grid-template-columns: 56rem 1fr;
  // }

  // @media only screen and (max-width: $bp-700) {
  //   grid-template-columns: 100%;
  // }

  &_nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding: 0 2rem;
    // border-top: solid 1px var(--color-primary);
    background-color: var(--color-white);
    border-bottom: solid 1px var(--color-dark);
    border-radius: 1rem 1rem 0 0;

    & > :not(:last-child) {
      margin-right: 1rem;
    }

    &_group {
      position: relative;
    }

    &_more {
      position: absolute;
      right: 0;
      top: 75%;
      background-color: var(--color-white);
      padding: 1.5rem;
      border-radius: 1rem;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
      z-index: 5;

      & > :not(last-child) {
        margin-bottom: 1rem;
      }
    }

    &_item {
      cursor: pointer;
      padding: 1rem 0;
      box-sizing: border-box;
      font-size: var(--default-font-small);

      // @media only screen and (max-width: $bp-600) {
      // 	width: 47%;
      // }

      &_active {
        cursor: pointer;
        padding: 1rem 0;
        box-sizing: border-box;
        font-size: var(--default-font-small);
        font-weight: 700;
        color: var(--color-fash-primary-3);
        border-bottom: solid 2px var(--color-fash-primary-3);
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        font-weight: 700;
        color: var(--color-fash-primary-3);
        border-bottom: solid 2px var(--color-fash-primary-3);
      }
    }
  }

  // & > :nth-child(3) {
  // 	grid-column: 1/3;
  // 	grid-row: 2/3;
  // }
}

.searchInMiddle {
  margin-top: 3rem;
  grid-column: 1/3;
  display: flex;
  width: 100%;
  justify-content: center;
}

// styles for recent transactions //

.recent {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  grid-column: 1/3;
  margin-top: 3rem;

  &__title {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    border-radius: 1rem;
    background-color: var(--color-primary-3);
    font-size: var(--default-font-small);

    &__active {
      background-color: var(--color-dark);
      color: var(--color-white);
      font-weight: 700;
    }

    & > * {
      padding: 1rem 0;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;

      @media only screen and (max-width: $bp-600) {
        text-align: center;
        padding: 1rem;
      }

      &:hover {
        background-color: var(--color-dark);
        color: var(--color-white);
        font-weight: 700;
      }
    }

    & > :not(:last-child) {
      border-right: 1px solid var(--color-primary);
    }

    & > :first-child {
      border-radius: 1rem 0 0 1rem;
    }

    & > :last-child {
      border-radius: 0 1rem 1rem 0;
    }
  }
}

// Transaction Date //

.date {
  background: var(--color-dark);
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
  font-weight: 700;
  font-size: var(--default-font-small);
  line-height: 1.7rem;
  color: var(--color-white);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

// Recent Transactions //

.rt {
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content {
    width: 95%;
    cursor: pointer;

    & > :not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

// Add Payment Type Styles //

.paymentType {
  display: flex;
  flex-direction: row;
  gap: 2.9rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.beside {
  display: flex;
  justify-content: space-between;
  width: 38rem;
}

.successImg {
  height: 19rem;
}

//Errors

.error {
  font-size: var(--default-font-small);
  color: var(--color-error);
  font-weight: 400;
}

.checks {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;
  gap: 7rem;
  width: 40rem;

  // &> :not(:last-child) {
  // 	margin-right: 1rem;
  // }
}

.incomeTable {
  margin-top: 2rem;

  table {
    width: 100%;
    max-width: 100%;
    border: none;
    border-collapse: collapse;
    box-sizing: border-box;
    text-align: left;
    font-size: var(--default-font-small);

    th,
    td {
      padding: 2.2rem 0;
    }

    th {
      font-weight: 700;
    }

    tr {
      display: grid;
      grid-template-columns: 1fr 2.5fr repeat(4, 1fr) 0.5fr;
      padding: 0 1.5rem;

      @media only screen and (max-width: $bp-600) {
        grid-template-columns: 1.5fr 2.5fr 1fr;
      }
    }

    tbody {
      & > :not(:last-child) {
        margin-bottom: 1.5rem;
      }

      tr {
        background-color: var(--color-primary-3);
        cursor: pointer;
        border-radius: 1.3rem;

        &:nth-child(even) {
          background-color: var(--color-primary-7);
        }

        & > :nth-child(7) {
          font-size: 3rem;
          position: relative;

          span {
            font-size: 1.2rem;
            width: fit-content;
            background-color: rgba(0, 0, 0, 0.75);
            color: var(--color-white);
            position: absolute;
            padding: 0.5rem;
            left: -1.6rem;
            border-radius: 0.5rem;
            text-align: center;
            opacity: 0;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.expenseTable {
  margin-top: 2rem;

  table {
    width: 100%;
    // max-width: 100%;
    border: none;
    border-collapse: collapse;
    box-sizing: border-box;
    text-align: left;
    font-size: var(--default-font-small);

    th,
    td {
      padding: 2.2rem 0;
    }

    th {
      font-weight: 700;
    }

    tr {
      display: grid;
      grid-template-columns: repeat(6, 1fr) 0.5fr;
      padding: 0 3rem;

      @media only screen and (max-width: $bp-600) {
        grid-template-columns: 1.5fr 2fr 2fr;
      }
    }

    tbody {
      & > :not(:last-child) {
        margin-bottom: 1.5rem;
      }

      tr {
        background-color: var(--color-primary-3);
        cursor: pointer;
        border-radius: 1.3rem;

        &:nth-child(even) {
          background-color: var(--color-primary-7);
        }

        & > :nth-child(7) {
          font-size: 3rem;
          position: relative;

          span {
            font-size: 1.2rem;
            width: fit-content;
            background-color: rgba(0, 0, 0, 0.75);
            color: var(--color-white);
            position: absolute;
            padding: 0.5rem;
            left: -1.6rem;
            border-radius: 0.5rem;
            text-align: center;
            opacity: 0;
            cursor: pointer;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

.expenseDebtTable {
  margin-top: 2rem;

  table {
    width: 100%;
    max-width: 100%;
    border: none;
    border-collapse: collapse;
    box-sizing: border-box;
    text-align: left;
    font-size: var(--default-font-small);
    position: relative;

    th,
    td {
      padding: 2.2rem 0;
    }

    th {
      font-weight: 700;
    }

    tr {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      padding: 0 3rem;
      align-items: center;
    }

    tbody {
      & > :not(:last-child) {
        margin-bottom: 1.5rem;
      }

      tr {
        background-color: var(--color-primary-3);
        cursor: pointer;
        border-radius: 1.3rem;

        &:nth-child(even) {
          background-color: var(--color-primary-7);
        }
      }
    }
  }
}

.notPaid {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-error);
  color: var(--color-white);
  margin: 1.5rem 0;
  width: 83%;
  text-align: center;
  padding: 1rem;
}

.partPaid {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-yellow);
  color: var(--color-dark);
  margin: 1.5rem 0;
  width: 83%;
  text-align: center;
  padding: 1rem;
}

.paid {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-green);
  color: var(--color-white);
  margin-bottom: 2rem;
  width: 83%;
  position: relative;
  top: 1rem;
  padding: 1rem;
}

.alignRight {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: var(--default-font-small);
  color: var(--color-primary);
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}

.tableButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  font-size: 2rem;
  width: 34rem;
  height: 5.7rem;
  padding: 2rem;
  background-color: var(--color-primary);
  border-radius: 1rem;
  cursor: pointer;
  z-index: 1000;

  &_active {
    transform: rotate(180deg);
    z-index: 10000;
  }
}

.dropDown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
  font-size: 2rem;
  box-sizing: border-box;
  border-bottom: 1px solid var(--color-dark);
  padding: 1rem;
  width: 34rem;
  cursor: pointer;
  align-content: center;
}

.receipt {
  &__list {
    margin-top: 2rem;
    display: flex;
    position: relative;
  }

  &__beside {
    display: flex;
    justify-content: space-between;
  }

  &__checks {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 2rem 0;
  }
}

.summary {
  width: 42rem;
  margin-top: 2rem;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__edit {
    display: flex;
    align-items: flex-end;
    color: var(--color-dark);
    font-size: var(--default-font-small);
    cursor: pointer;

    & > :last-child {
      font-size: 3rem;
      color: var(--color-primary);
    }

    &:hover,
    &:focus {
      color: var(--color-primary-4);
      font-weight: 700;
    }
  }

  &__line {
    border-top: 1px solid var(--color-primary);
    margin: 2rem 0;
  }

  &__beside {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__dates {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;

    & > :not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__details {
    color: var(--color-primary);
  }

  &__total {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  &__account {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: 1rem;
    margin: 2rem 0;
  }

  &__button {
    display: flex;
    gap: 2rem;
  }
}

.noRecord {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75.5%;
  text-align: center;
}

//transaction new update

.txFilterSection {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

.txSelect {
  width: 30rem;
  font-size: var(--default-font-small);

  &__title {
    width: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.9rem 2.2rem;
    background-color: var(--color-white);
    border-radius: 1rem;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    color: var(--color-fash-primary-3);

    &:hover {
      color: var(--color-fash-primary-3);
      background-color: var(--color-light-blue-2);
    }

    &__icon {
      font-size: 2rem;

      &__clicked {
      }
    }
  }

  &__content {
    width: 100%;
    background-color: var(--color-white);
    border-radius: 1rem;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    cursor: pointer;
    padding: 0.9rem 0;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    max-height: 20rem;
    overflow-y: scroll;

    span {
      padding: 1rem 2.2rem;

      &:hover,
      &:active,
      &:focus {
        background-color: var(--color-light-blue-2);
        color: var(--color-primary);
        font-weight: 700;
      }
    }
  }
}
