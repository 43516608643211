@import "./variables/breakpoints";

.outline {
  width: 100%;
  border: 1px dashed var(--color-grey);
  min-height: 15rem;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--default-font-small);
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.group {
  margin-bottom: 2rem;
}

.label {
  font-size: var(--default-font-small);
  margin-bottom: 0.5rem;
  padding-left: 2rem;
  font-size: 1.4rem;
}

.upload {
  background-color: var(--color-fash-primary-4);
  color: var(--color-dark);
  border-radius: 0.8rem;
  padding: 1rem;
}

.content {
  width: 100%;
  border-radius: 1rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.preview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 1rem;

  & > * {
    position: relative;
    max-width: 100%;

    p {
      word-break: break-all;
    }
  }

  img {
    max-width: 100%;
    margin-bottom: 1rem;
    height: 10rem;
    object-fit: cover;
  }
}

.remove {
  position: absolute;
  color: var(--color-error);
  z-index: 2;
  font-size: 2.5rem;
  right: -1rem;
  top: -1rem;
  cursor: pointer;
  background-color: var(--color-white);
  border: none;

  &:hover {
    color: var(--color-deep-red);
  }
}

.rejected {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: var(--color-light-red);
  padding: 1rem;
  margin-top: 1rem;

  &__msg {
    color: var(--color-error);
  }
}
