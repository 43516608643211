@import "../variables/breakpoints";

.bi {
  width: 100%;
  border-radius: 2rem;
  background-color: var(--color-white);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 3.5rem 2rem;
  height: fit-content;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    &_text {
      padding: 0;
      margin: 0;
      font-size: var(--default-font-size);
      font-weight: 700;
    }

    &_link {
      color: var(--color-fash-primary-3);
      text-decoration: underline;
      font-weight: 700;
    }
  }

  &__content {
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: $bp-1000) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__card {
    width: 100%;
    height: 10rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    padding: 0 1.5rem;
    border-radius: 1rem;
    cursor: pointer;

    &__outline {
      border: 1px solid var(--color-fash-primary-3);
      transition: all 500ms;

      &:hover {
        color: var(--color-fash-primary-3);
        border: 1px solid var(--color-fash-primary-3);
      }
    }

    &__title {
      font-size: var(--default-font-small);
      line-height: 1.8rem;
    }

    &__value {
      font-weight: 700;
      line-height: 2.2rem;

      @media only screen and (max-width: $bp-400) {
        font-size: var(--default-font-small);
      }
    }
  }
}

.selectCurrency {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--default-font-small);
  position: relative;
  margin-left: 2rem;

  &__text {
    display: flex;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    background-color: #f2f0f0;
    color: var(--color-primary);
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    border: solid 1px var(--color-fash-primary-3);
    cursor: pointer;
    transform: rotateZ(0);
    transition-duration: 500ms;

    &:hover {
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-fash-primary-3);
      transform: rotateZ(-180deg);
    }

    &_active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      background-color: #f2f0f0;
      color: var(--color-primary);
      width: 5rem;
      height: 5rem;
      border-radius: 5rem;
      border: solid 1px var(--color-primary-4);
      cursor: pointer;
      transition-duration: 500ms;
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 5rem;
    background-color: #f2f0f0;
    color: var(--color-dark);
    border-radius: 1rem;
    box-shadow:
      10px 10px 45px #bebebe,
      -15px -15px 30px #ffffff;
    position: absolute;
    top: 6rem;
    z-index: 30;
    border: solid 1px var(--color-primary-4);

    &_active {
      background-color: var(--color-primary-7);
      color: var(--color-primary);
      font-weight: 700;
    }

    li {
      list-style: none;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1rem 0;
      box-sizing: border-box;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-7);
        color: var(--color-primary);
        font-weight: 700;
      }
    }
  }
}
