.beneficiary {
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  font-size: var(--default-font-small);
  width: 38rem;

  margin-bottom: 3rem;
  cursor: pointer;

  & > * {
    padding: 1rem 0 1rem 0;
    text-align: center;
  }

  &__saved {
    background-color: var(--color-fash-primary-3);
    color: var(--color-white);
    border-radius: 1rem 0 0 1rem;
    transition: all 0.4s;

    &_b {
      border: 1px solid var(--color-fash-primary-3);
      border-radius: 1rem 0 0 1rem;
      transition: all 0.4s;
    }
  }

  &__new {
    border: 1px solid var(--color-fash-primary-3);
    border-radius: 0 1rem 1rem 0;
    transition: all 0.4s;

    &_b {
      background-color: var(--color-fash-primary-3);
      color: var(--color-white);
      border-radius: 0 1rem 1rem 0;
      transition: all 0.4s;
    }
  }
}

//Pin styles
.pin {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  padding-left: 2rem;

  &__label {
    font-size: var(--default-font-small);
    font-family: inherit;
    padding-left: 4rem;
  }

  &__group {
    margin-top: 2rem;
  }
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.confirmImage {
  width: 20rem;
  margin-bottom: 5rem;
}

.transferSuccessImg {
  width: 30rem;
  margin-bottom: 5rem;
}

.transferFailedImg {
  width: 20rem;
  margin-bottom: 5rem;
}

.buttonBeside {
  display: flex;
  gap: 3rem;
  align-items: center;
}

//Beneficiaries
.beneficiaries {
  display: flex;
  align-items: center;
  width: 38rem;
  & > :not(:last-child) {
    margin-right: 1rem;
  }

  &__details {
    width: 33rem;
    cursor: pointer;

    &__bank {
      text-transform: uppercase;
      font-size: var(--default-font-small);
    }

    &:hover {
      color: var(--color-fash-primary-3);
      font-weight: 700;
    }
  }

  &__delete {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--color-error);
    cursor: pointer;
    font-size: 3rem;

    &:hover {
      font-weight: 700;
    }

    p {
      font-size: 1.4rem;
      color: var(--color-deep-red);
    }
  }
}

.line {
  margin: 1rem 0;
  border-top: 1px solid var(--color-primary);
}
.bankList {
  width: 38rem;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  &:hover {
    color: var(--color-primary);
    font-weight: 700;
  }
}
