@import "../variables/breakpoints";

.sb {
  background-color: var(--color-fash-black);
  color: var(--color-white);
  box-sizing: border-box;
  padding: 4rem 2rem;
  min-height: 100vh;
  cursor: pointer;
  width: 30vw;
  transition: all 850ms;
  position: relative;

  @media only screen and (max-width: $bp-medium) {
    width: 40vw;
  }

  @media only screen and (max-width: $bp-small) {
    width: 70vw;
  }

  &_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;

    &> :not(:last-child) {
      margin-right: 3rem;
    }
  }

  &_icon {
    font-size: 3rem;
  }

  &_logo {
    height: 3rem;
  }

  &_nav {
    box-sizing: border-box;
    padding: 3rem 1rem;
    height: 87vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0.5rem;
      background-color: var(--color-white);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-grey);
    }

    &_2 {
      box-sizing: border-box;
      height: 50vh;
      padding: 3rem 1rem;
      overflow-y: auto;

      @media only screen and (max-width: $bp-1300) {
        height: 50vh;
      }

      @media only screen and (max-width: $bp-1000) {
        height: 60vh;
      }

      @media only screen and (max-width: $bp-600) {
        height: 57vh;
      }

      @media only screen and (max-width: $bp-400) {
        height: 52vh;
      }

      &::-webkit-scrollbar {
        width: 0.5rem;
        background-color: var(--color-white);

        &:active,
        &:hover {
          width: 1rem;
        }
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--color-grey);
      }
    }

    &_ul {
      display: flex;
      flex-direction: column;
      align-items: space-evenly;
      margin: 0;
      padding: 0;
      gap: 1rem;
      list-style: none;

      &>* {
        padding: 0.75rem 2rem;
        list-style: none;
      }
    }

    &_item {
      font-size: var(--default-font-small);
      font-weight: 700;

      &_active,
      &:hover {
        background-color: var(--color-white);
        color: var(--color-dark);
        border-radius: 0.75rem;
      }
    }

    &_link {
      display: flex;
      align-items: center;
      gap: 1rem;

      &_icon {
        font-size: 2rem;
      }

      &_arr {
        font-size: 2rem;
        transform: rotateX(180deg);

        &_1 {
          font-size: 2rem;
        }
      }

      &_group {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.subNav {
  &_ul {
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
    margin: 1rem 0;
    padding: 0;
    gap: 0.5rem;
    list-style: none;

    &>* {
      padding: 0.75rem 3rem;
      list-style: none;
    }
  }

  &_li {

    &_active,
    &:hover {
      background-color: var(--color-fash-black);
      color: var(--color-white);
      border-radius: 0.75rem;
    }
  }
}