@import "../variables/breakpoints";

.main {
  display: grid;
  grid-template-columns: 72rem 1fr;
  grid-template-rows: max-content min-content;
  gap: 3rem;

  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: 1fr;
  }

  .tagBanner {
    position: absolute;
    z-index: 100;
    top: 1px;
    left: 1px;
  }
}

.homeTop {
  display: flex;

  & > :not(:last-child) {
    margin-right: 2rem;
  }

  @media only screen and (max-width: $bp-500) {
    flex-direction: column;
  }
}

.multi {
  width: 38rem;
  background-color: var(--color-white);
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 1.5rem 2rem;
  margin-bottom: 2rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  font-size: var(--default-font-size);
  color: var(--color-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;

  & > :first-child {
    cursor: pointer;
    &:hover {
      font-weight: 700;
      color: var(--color-dark);
    }
  }

  @media only screen and (max-width: $bp-500) {
    width: 100%;
  }

  &_select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    margin-bottom: 1.5rem;
    width: 100%;
    color: var(--color-dark);
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--color-dark);

    &_close {
      margin-left: 2rem;
      color: var(--color-error);
      font-size: 3rem;
    }
  }

  &_biz {
    &_list {
      display: flex;
      color: var(--color-dark);
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
      cursor: pointer;
      box-sizing: border-box;
      width: 100%;

      &:hover {
        background-color: var(--color-primary-4);
        padding: 2rem 1rem;
        color: var(--color-white);
        font-weight: 700;
      }

      &_active {
        display: flex;
        background-color: var(--color-primary-4);
        color: var(--color-white);
        padding: 2rem 1rem;
        font-weight: 700;
        margin-bottom: 1rem;
        border-radius: 1rem;
        cursor: pointer;
        box-sizing: border-box;
        width: 100%;
      }

      &_btns {
        margin-top: 4rem;
        border-top: 1px solid var(--color-dark);
        padding-top: 2rem;
        width: 100%;
      }
    }
  }
}

.topFilter {
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  border-radius: 1rem;
  box-sizing: border-box;
  padding: 1.5rem 3rem;
  margin-bottom: 2rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  width: fit-content;

  & > :not(last-child) {
    margin-bottom: 2rem;
  }
}

.filter {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 3rem;
  background-color: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;

  &_button {
    display: flex;
    align-items: center;
    color: var(--color-primary);
    cursor: pointer;
    font-size: var(--default-font-small);

    &:hover {
      color: var(--color-primary-4);
    }

    &_icon {
      font-size: 3rem;
      margin-right: 1rem;
    }
  }

  &_line {
    margin-top: 1rem;
    border-top: 1px solid var(--color-primary);
  }

  &_content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    & > :not(last-child) {
      margin-right: 1rem;
    }
  }
}

.toDo {
  grid-column: 1/3;
  background-color: var(--color-white);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 3rem 3rem;
  border-radius: 1rem;

  &__group {
    display: flex;
    font-size: var(--default-font-small);
    justify-content: space-between;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    & > :not(last-child) {
      margin-right: 2rem;
    }
  }

  &__items {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_hover {
      display: flex;
      align-items: center;
      cursor: pointer;
      color: red;

      & > :not(last-child) {
        margin-right: 1rem;
      }

      span {
        font-size: 2.5rem;
      }

      &:hover {
        color: var(--color-primary);
        font-weight: 700;
      }
    }

    & > :not(last-child) {
      margin-right: 1rem;
    }

    span {
      font-size: 2.5rem;
    }
  }

  &__green {
    color: var(--color-success);
  }

  @media only screen and (max-width: $bp-700) {
    grid-column: 1/2;
  }
}

.selectCurrency {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--default-font-small);
  position: relative;
  margin-left: 2rem;

  &__text {
    display: flex;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    background-color: #f2f0f0;
    color: var(--color-primary);
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    border: solid 1px var(--color-primary-4);
    cursor: pointer;
    transform: rotateZ(0);
    transition-duration: 500ms;

    &:hover {
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }

    &_active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      background-color: #f2f0f0;
      color: var(--color-primary);
      width: 5rem;
      height: 5rem;
      border-radius: 5rem;
      border: solid 1px var(--color-primary-4);
      cursor: pointer;
      transition-duration: 500ms;
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 5rem;
    background-color: #f2f0f0;
    color: var(--color-dark);
    border-radius: 1rem;
    box-shadow:
      10px 10px 45px #bebebe,
      -15px -15px 30px #ffffff;
    position: absolute;
    top: 6rem;
    z-index: 30;
    border: solid 1px var(--color-primary-4);

    &_active {
      background-color: var(--color-primary-7);
      color: var(--color-primary);
      font-weight: 700;
    }

    li {
      list-style: none;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1rem 0;
      box-sizing: border-box;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-7);
        color: var(--color-primary);
        font-weight: 700;
      }
    }
  }
}

.expense {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: var(--default-font-h3);
  margin-top: 3rem;

  & > :not(:last-child) {
    margin-right: 2rem;
  }
}
.insight {
  display: flex;
  width: auto;
  gap: 3rem;
}
