@import '../variables/breakpoints';

.rcb {
	display: inline-flex;
	align-items: center;
	font-size: var(--default-font-size);
	cursor: pointer;
	position: relative;
	max-width: 40rem;

	@media only screen and (max-width: $bp-600) {
		font-size: var(--default-font-small);
	}

	&__input {
		display: none;
	}

	&__check {
		width: 2.5rem;
		height: 2.5rem;
		border: 2px solid var(--color-dark);
		cursor: pointer;
		border-radius: 0.5rem;
		box-sizing: border-box;
		margin-right: 1rem;
		padding: 3px;

		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background-color: var(--color-dark);
			display: block;
			border-radius: 0.3rem;

			transform: scale(0);
			transition: transform 0.2s;
		}
	}

	&__input:checked~&__check::after {
		transform: scale(1);
	}

	&:hover &__input~&__check {
		border: 3px solid var(--color-fash-primary-2);
	}
}