@import '../variables/breakpoints';

.tw {
	width: 100%;
	border-radius: 2rem;
	background-color: var(--color-white);
	box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
	box-sizing: border-box;
	padding: 2rem 2rem;

	&__title {
		padding: 0;
		margin: 0;
		font-size: var(--default-font-size);
		font-weight: 700;
		margin-bottom: 1rem;
	}

	&__content {
		display: grid;
		box-sizing: border-box;
		grid-template-columns: repeat(2, 1fr);
		gap: 1rem 2rem;
		@media only screen and (max-width: $bp-medium) {
			grid-template-columns: 1fr;
		}
	}

	&__card {
		width: 100%;
		height: 10rem;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 1.5rem;
		border-radius: 1rem;
		cursor: pointer;
		border: 1px solid var(--color-grey);
		transition: all 0.4s;

		&:hover {
			border: 2px solid var(--color-primary);
			color: var(--color-primary);
			transform: translateX(3px) translateY(3px);
			box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
			background-color: var(--color-primary-3);
		}

		&__text {
			display: flex;
			flex-direction: column;
			gap: 1rem;
			justify-content: center;
		}

		&__title {
			font-size: var(--default-font-small);
			line-height: 1.8rem;
		}

		&__value {
			font-weight: 700;
			line-height: 2.2rem;
		}
	}

	&__icon {
		font-size: 2.4rem;
	}
}
