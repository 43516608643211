@import '../variables/breakpoints';

//Styling the container
.container {
	display: grid;
	grid-template-columns: 5.8fr 4.2fr;
	max-width: 100vw;

	@media only screen and (max-width: 56.25em) {
		display: flex;
		flex-direction: column;
		gap: 3rem;
	}
}

// Styling the left side
.leftside {
	display: grid;
	row-gap: 2rem;
	background-color: var(--color-light);
	grid-template-rows: 3.8rem min-content;
	padding: 3rem 10rem 0 10rem;
	align-content: center;
}

.logo {
	height: 3.8rem;
	@media only screen and (max-width: 56.25em) {
		margin-top: 5rem;
	}
	@media only screen and (max-width: 31.25em) {
		justify-self: center;
	}
}

.slider {
	display: grid;
	grid-template-rows: 32.8rem repeat(3, max-content);
	row-gap: 2.5rem;
	@media only screen and (max-width: 56.25em) {
		margin-top: 10rem;
	}

	@media only screen and (max-width: 31.25em) {
		margin-top: 20rem;
		display: flex;
		flex-direction: column;
	}

	&__img {
		height: 32.8rem;
		justify-self: center;
		grid-column: 1 / span 1;
		grid-row: 1 / span 1;
	}

	&__title {
		color: var(--color-primary);
		font-size: var(--default-font-h3);
		font-weight: 700;

		@media only screen and (max-width: 56.25em) {
			text-align: center;
		}
		@media only screen and (max-width: 31.25em) {
			font-size: var(--default-font-subtitle);
			color: var(--color-dark);
			font-weight: 400;
			text-align: center;
		}
	}

	&__icon {
		display: flex;

		&__default {
			content: '';
			background-color: var(--color-primary);
			height: 2rem;
			width: 2rem;
			border-radius: 50%;
			margin-right: 1rem;
		}

		&__outline {
			content: '';
			border: 1px solid var(--color-primary);
			height: 2rem;
			width: 2rem;
			border-radius: 50%;
			margin-right: 1rem;
		}
	}
}

//Styling right side
.rightside {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: var(--color-white);
	min-height: 100vh;
	padding-top: 2rem;
	padding-bottom: 2rem;

	@media only screen and (max-width: 56.25em) {
		background-color: var(--color-light);
		min-height: auto;
	}

	&__back {
		display: flex;
		align-items: center;
		font-size: 4.5rem;
		color: var(--color-primary);
		margin: 2rem 0;
		cursor: pointer;
		
		&:hover {
			color: var(--color-primary-4);
		}
	}
}

.logintext {
	&__center {
		text-align: center;
	}

	&__title {
		font-size: var(--default-font-subtitle);
		font-weight: 700;
		color: var(--color-primary);
		line-height: 3rem;
	}

	&__p {
		font-size: var(--default-font-small);
		font-weight: 400;

		line-height: 1.7rem;
		margin-top: 2rem;
		margin-bottom: 3rem;
	}
}

//FORM side
.form {
	display: block;

	&__group:not(:last-child) {
		margin-bottom: 1rem;
	}

	&__pin__input {
		font-size: var(--default-font-size);
		font-family: inherit;
		color: var(--color-grey);
		width: 6rem;
		height: 6rem;
		border-radius: 1rem;
		background-color: var(--color-white);
		border: 1px solid var(--color-dark);
		display: block;
		text-align: center;

		&:focus,
		&:focus-visible,
		&:focus-within {
			color: var(--color-dark);
			border: 1px solid var(--color-primary);
		}
	}

	&__input {
		font-size: var(--default-font-size);
		font-family: inherit;
		color: var(--color-grey);
		padding-left: 2rem;
		width: 38rem;
		height: 6rem;
		border-radius: 1rem;
		background-color: var(--color-white);
		border: 1px solid var(--color-dark);
		display: block;

		&:focus,
		&:focus-visible,
		&:focus-within {
			color: var(--color-dark);
			border: 1px solid var(--color-primary);
		}
	}

	&__label {
		font-size: var(--default-font-small);
		font-family: inherit;
		padding-left: 2rem;
	}
}

// When form is filled

.filled {
	color: var(--color-dark);
}

//otp styles
.otp__group {
	display: flex;
	gap: 2rem;
	margin-top: 1rem;
}

// button style
button {
	font-family: 'Montserrat', sans-serif;
	font-size: var(--default-font-size);
	display: flex;
	background-color: transparent;
	border: none;
}

.btn {
	padding: 1.9rem 2.2rem;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-weight: 700;
	border-radius: 1rem;
	cursor: pointer;

	&__outline {
		border: 1px solid var(--color-dark);
		margin-right: 2.3rem;
		color: var(--color-dark);

		&:hover {
			border: 1px solid var(--color-primary);
			color: var(--color-primary);
		}
	}

	&__default {
		background-color: var(--color-primary)-2;
		color: var(--color-white);

		&:hover {
			background-color: var(--color-primary)-4;
		}
	}

	&__default_38 {
		background-color: var(--color-primary)-2;
		color: var(--color-white);
		width: 38rem;

		&:hover {
			background-color: var(--color-primary)-4;
		}
	}

	&__icon {
		height: 2.2rem;
		margin-right: 1rem;
	}

	&__form {
		background-color: var(--color-primary)-2;
		color: var(--color-white);
		width: 100%;
		margin-top: 2rem;

		&:hover {
			background-color: var(--color-primary)-4;
		}
	}
}

.accent {
	font-size: var(--default-font-small);
	font-weight: 700;
	text-align: end;
	cursor: pointer;
}

.clickable {
	color: var(--color-primary);
	cursor: pointer;
}

.emphasized {
	color: var(--color-primary);
	font-size: var(--default-font-size);
}

//Color styles
.color__primary {
	color: var(--color-primary);
}

//Random usable styles
.mt_40 {
	margin-top: 4rem;
}

.mt_30 {
	margin-top: 3rem;
}

.mb_30 {
	margin-bottom: 3rem;
}

.mb_10 {
	margin-bottom: 1rem;
}

//Errors

.error {
	font-size: var(--default-font-small);
	color: var(--color-error);
	padding-left: 2rem;
	font-weight: 400;
	margin: 1rem 0;
	max-width: 38rem;
}

// Success
.success {
	&__h3 {
		color: var(--color-success);
		font-size: var(--default-font-h3);
		font-weight: 700;
		line-height: 3.5rem;
	}
}

//Password Image change
.pw__img {
	max-width: 25rem;
}

.account {
	display: grid;
	grid-template-columns: 1fr 1fr;
	box-sizing: border-box;
	font-size: var(--default-font-small);
	width: 38rem;

	margin-bottom: 3rem;
	cursor: pointer;

	& > * {
		padding: 1rem 0 1rem 0;
		text-align: center;
	}

	&__owner {
		background-color: var(--color-primary);
		color: var(--color-white);
		border-radius: 1rem 0 0 1rem;
		transition: all 0.4s;

		&_b {
			border: 1px solid var(--color-primary);
			border-radius: 1rem 0 0 1rem;
			transition: all 0.4s;
		}
	}

	&__employee {
		border: 1px solid var(--color-primary);
		border-radius: 0 1rem 1rem 0;
		transition: all 0.4s;

		&_b {
			background-color: var(--color-primary);
			color: var(--color-white);
			border-radius: 0 1rem 1rem 0;
			transition: all 0.4s;
		}
	}

	&__desc {
		font-size: var(--default-font-small);
		width: 38rem;
		text-align: center;
		margin-bottom: 3rem;

		span {
			font-weight: 700;
			color: var(--color-primary);
		}
	}
}

//sign up referral code input field
input:disabled {
	cursor: not-allowed;
	background-color: var(--color-grey-3);
}

//used in new beneficiary
.checkbox{
	display: flex;
	justify-content: space-between;
	margin: 1rem 0 2rem 0;
	width: 100%;

	&__group {
		align-self: center;
		width: 100%;
	}
}