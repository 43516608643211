.inv {
	width: 38rem;
	display: grid;
	grid-template-columns: 1.8fr 1fr;
	gap: 2rem;

	box-sizing: border-box;
	border-radius: 1rem;
	background-color: var(--color-primary-3);
	padding: 1rem 2rem;
	position: relative;
	margin-bottom: 2rem;

	&__expand {
		position: absolute;
		right: 2rem;
		bottom: 1rem;
		cursor: pointer;

		&:hover {
			color: var(--color-primary);
		}
	}

	&__main {
		font-weight: 700;
		color: var(--color-primary-4);
	}

	&__other {
		color: var(--color-primary-4);
		font-size: 1.4rem;
	}
}

.item {
	display: grid;
	grid-template-columns: 1.3fr 1fr;
	gap: 2rem;
	margin-bottom: 1rem;

	&__qty {
		font-size: var(--default-font-small);
	}

	&__total {
		font-weight: 700;
		color: var(--color-fash-primary-3);
	}
}
