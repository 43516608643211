@import '../variables/breakpoints';

.main {
	background-color: var(--color-primary-3);
	background-image: url('../../img/background.png');
	background-size: cover;
	transition: all 0.4s;
	width: 38rem;
	border-radius: 1rem;
	padding: 2rem;
	box-sizing: border-box;
	margin-bottom: 2rem;

	&__icon {
		 height: 3rem;		
	}

	&__cardDetails {
		display: flex;
		gap: 3rem;
		color: var(--color-dark-web);
	}
}

.cardInfo {
	width: 38.4rem;
	padding: 1rem 0;
	margin-top: 2rem;
	box-sizing: border-box;
	border-radius: 1rem;
	line-height: 1rem;
	font-size: 0.8rem;
	border: 1px solid #B3B3C5;	
	align-items: baseline;
	margin-bottom: 2rem;



	&__items {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #B3B3C5;
		padding: 1.5rem;

		&_item {
			display: flex;			
			font-size: 1.4rem;
			line-height: 1.2rem;
			margin-top: 0.5rem;
		}
	}
}

.imgBg {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
	margin-top: 6rem;
	margin-bottom: 2rem;
	background-color: var(--color-primary-3);

	&__img {
		width: 7rem;
		height: 7rem;
	}
}

.cardBottom {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.cardNo {
	width: 100%;
	display: flex;
	justify-content: center;
	font-family: 'Courier New', Courier, monospace;
	font-weight: 700;
	font-size: 2.4rem;
	margin: 1rem 0 2rem 0;
}

.cardBeside {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;

	& >:not(:last-child) {
		margin-right: 1rem;
	}
}

.fullCard {
	width: 38rem;
	& >:not(:last-child) {
		margin-right: 1rem;
	}

	&_info {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		padding-bottom: 1rem;
		border-bottom: 1px solid var(--color-primary);
		width: 38rem;
	}

	&_icon {
		font-size: 3rem;

		&:hover,
		&:focus,
		&:active {
			color: var(--color-primary);
		}

	}


}