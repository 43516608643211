:root {
  //COLORS
  --color-light: #f4f9ff;
  --color-white: #ffffff;
  --color-white-2: #fbfcfe;

  --color-primary: #1f68d6;
  --color-primary-2: #3b7ee3;
  --color-primary-3: #ccedff;
  --color-primary-4: #003c97;
  --color-primary-7: #c1daff;
  --color-primary-8: #97c1ff;
  --color-primary-09: #ccedff;
  --color-mima-light: #eef2f5;

  --color-error: #e12f2f;
  --color-success: #66b032;
  --color-green: #569755;
  --color-green-2: #92e391;
  --color-green-3: #d3eace;
  --color-yellow: #e7d788;
  --color-yellow-2: #ffaa00;
  --color-yellow-3: #8f4f00;
  --color-light-red: #f7d1cd;
  --color-deep-red: #9f0000;

  --color-grey: #b3b3c5;
  --color-grey-2: #d1d1d1;
  --color-grey-3: #e0e9f3;
  --color-grey-4: #f9f9fa;
  --color-grey-5: #ebebf5;
  --color-grey-6: #eaecef;
  --color-grey-7: #fafafa;
  --color-grey-8: #707a8a;
  --color-table-grey: #fafafc;
  --color-table-grey-1: #f4f4fc;

  // --color-dark: #4a4a68;
  // --color-dark: #0C090D;
  --color-dark: #252624;

  --color-dark-2: #1a1a42;
  --color-dark-3: #696365;
  --color-dark-6: #2a2828;

  --color-disabled: #c0c0c0;
  --color-light-blue: #ebf2ff;
  --color-light-blue-2: #d4e5ff;
  --color-light-blue-3: #e7f3fe;

  --color-dark-yellow: #7e6e1f;
  --color-light-brown: #f4f5f6;
  --color-light-green: #e4ffec;
  --color-light-yellow: #fff5d7;
  --color-light-red: #ffe8e8;
  --color-light-grey: #f3f3ff;

  --color-admin: #112855;
  --color-admin-2: #071530;
  --color-admin-3: #020710;

  --color-fash-primary: #f5cc16;
  --color-fash-primary-2: #c4a108;
  --color-fash-primary-3: #927700;
  --color-fash-primary-4: #f5e8b0;
  --color-fash-black: #0c090d;
  --color-fash-black-2: #312d33;

  --color-purple: #6a3c8f;
  --color-purple-1: #430d6e;

  --color-fash-green: #cbebca;
  --color-fash-green-1: #ebf2e9;
  --color-fash-green-2: #035e00;
  --color-fash-green-3: #023300;

  --color-fash-wine: #824670;

  --color-moneyex-primary: #25c900;
  --color-moneyex-primary-2: #6bf04d;
  --color-moneyex-primary-3: #305627;
  --color-moneyex-primary-4: #0a2404;

  // FONT SIZES
  --default-font-size: 1.8rem;
  --default-font-h1: 6.4rem;
  --default-font-h2: 4rem;
  --default-font-h3: 3.2rem;
  --default-font-subtitle: 2.4rem;
  --default-font-small: 1.4rem;
  --default-font-xsmall: 1.2rem;

  //FONT
  --primary-font: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --secondary-font: "Rowdies", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  --tertiary-font: "Rubik", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

//COLORS
$color-light: #f4f9ff;
$color-white: #ffffff;
$color-white-2: #fbfcfe;

$color-primary: #1f68d6;
$color-primary-2: #3b7ee3;
$color-primary-3: #ccedff;
$color-primary-4: #003c97;
$color-primary-7: #c1daff;
$color-primary-09: #ccedff;

$color-error: #e12f2f;
$color-success: #66b032;
$color-green: #569755;
$color-green-2: #92e391;
$color-yellow: #e7d788;
$color-light-red: #f7d1cd;

$color-grey: #b3b3c5;
$color-grey-2: #d1d1d1;
$color-grey-3: #e0e9f3;
$color-grey-4: #d1d1d1;
$color-grey-6: #9898ab;

$color-dark: #4a4a68;
$color-dark-3: #696365;
$color-dark-6: #2a2828;

// FONT
$default-font-size: 1.8rem;
$default-font-h1: 6.4rem;
$default-font-h2: 4rem;
$default-font-h3: 3.2rem;
$default-font-subtitle: 2.4rem;
$default-font-small: 1.4rem;

// SHADOWS
$shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
$shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);