@import "../variables/breakpoints";

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 4rem;

  &_nav {
    width: 30%;
    border-radius: 2rem;
    background-color: var(--color-white);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    padding: 3.5rem 2rem;

    &_item {
      width: 100%;
      border: 1px solid var(--color-fash-primary-4);
      color: var(--color-dark);
      border-radius: 1rem;
      padding: 1rem 1rem;
      cursor: pointer;
      transition: all 0.2s;
      margin: 1rem;

      &_active {
        width: 100%;
        border: 1px solid var(--color-fash-primary-4);
        color: var(--color-dark);
        border-radius: 1rem;
        padding: 1rem 1rem;
        cursor: pointer;
        transition: all 0.2s;
        // margin: 1rem;
        border: 2px solid var(--color-fash-primary-2);
        color: var(--color-dark);
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
        background-color: var(--color-fash-primary-4);
      }

      &:hover {
        border: 2px solid var(--color-fash-primary-2);
        color: var(--color-dark);
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
        background-color: var(--color-fash-primary-4);
      }
    }
  }

  &_form {
    width: 70%;
    border-radius: 2rem;
    background-color: var(--color-white);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    padding: 3.5rem 2rem;

    // @media only screen and (max-width: $bp-600) {
    //   width: 100%;
    // }
  }
}

.checks {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;
  @media only screen and (max-width: $bp-900) {
    flex-direction: column;
    gap: 2rem;
  }
}
.dropZone {
  display: flex;
  width: 38rem;
  justify-content: center;
  align-items: center;
  background-size: contain;
}
