@import '../variables/breakpoints';

.main {
	background-color: var(--color-dark-2);
	max-width: 100vw;
    min-height: 100vh;
    padding: 5rem;
	box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__img{
        height: 7rem;
    }

	&__titles {
		width: 60rem;
		@media only screen and (max-width: $bp-500) {
            width: 42rem;
        }
	}

    &__form {
        background-color: var(--color-white);
		padding: 4rem 2rem;
		margin: 1rem 3rem;
		border-radius: 2rem;
		box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
		width: 50rem;

        @media only screen and (max-width: $bp-500) {
            width: 38rem;
        }
    }
}

.beside {
	display: grid;
	gap: 2rem;
	grid-template-columns: repeat(2, 1fr);
	margin-bottom: 2rem;

	@media only screen and (max-width: $bp-400) {
		grid-template-columns: 100%;
	}
}

.mima {
	display: flex;
	align-items: center;
    justify-content: center;
	margin-top: 3rem;

	& > :not(:last-child) {
		margin-right: 1rem;
	}

	&__logo {
		height: 3rem;
	}
}