@import "../variables/breakpoints";

.themes {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media only screen and (max-width: $bp-600) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.theme {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    padding: 2rem;
    background-color: var(--color-grey-7);
    cursor: pointer;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    height: fit-content;


    &> :first-child {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        align-items: center;
        margin-bottom: 2rem;
    }


    &__active {
        border: 2px solid var(--color-fash-green-2);
    }

    &:hover,
    &__set {
        border: 2px solid var(--color-fash-primary-2);
    }


    img {
        width: 100%;
    }
}