@import "../variables/breakpoints";

.standardSize {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.part {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding-bottom: 1rem;
  gap: 2rem;
}

.img {
  display: flex;
  width: 38rem;
  justify-content: center;
  align-items: center;
  background-size: contain;
}
