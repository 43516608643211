@import '../variables/breakpoints';

.container {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100vw;
    padding: 5rem;

    @media only screen and (max-width: $bp-600) {
        padding: 3rem 2rem;
    }
}

.appright {
    box-sizing: border-box;
    padding: 3rem 3rem;
    width: 80vw;

    @media only screen and (max-width: $bp-1000) {
        width: 90%;
    }

    @media only screen and (max-width: $bp-800) {
        width: 100%;
    }


}

.topside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    &_left {
        display: flex;
        align-items: center;

        &> :not(:last-child) {
            margin-right: 2rem;
        }
    }

    &_1 {
        display: flex;
        align-items: center;

        &> :not(:last-child) {
            margin-right: 3rem;
        }
    }

    &_welcome {
        font-size: var(--default-font-small);
        line-height: 1.8rem;
    }

    &_name {
        color: var(--color-primary);
        font-size: var(--default-font-subtitle);
        font-weight: 700;
        line-height: 3rem;
    }

    &_icon {
        display: flex;
        align-items: center;

        @media only screen and (max-width: $bp-500) {
            margin-bottom: 1rem;
        }
    }


    h3 {
        font-size: var(--default-font-h3);
        line-height: 3.6rem;

        @media only screen and (max-width: $bp-600) {
            font-size: var(--default-font-subtitle);
        }
    }
}

.menuIcon {
    font-size: 3rem;
    color: var(--color-fash-black);
    cursor: pointer;

    &:focus,
    &:hover {
        color: var(--color-fash-primary-2);
    }
}

.mobileSidebar {
    width: 100vw;
    height: 100vh;
    background-color: rgba(#2a2828, 0.7);
    position: fixed;
    display: grid;
    grid-template-columns: 70vw 30vw;
    z-index: 100;
}

.sidebar {
    width: 100vw;
    height: 100vh;
    background-color: rgba(#2a2828, 0.7);
    position: fixed;
    display: grid;
    grid-template-columns: 30vw 70vw;
    z-index: 100;
    top: 0;
    left: 0;

    &> :nth-child(2) {
        cursor: pointer;
    }

    @media only screen and (max-width: $bp-medium) {
        grid-template-columns: 40vw 60vw;
    }

    @media only screen and (max-width: $bp-small) {
        grid-template-columns: 70vw 30vw;
    }
}