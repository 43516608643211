@import "../variables/breakpoints";

.upper {
  display: grid;
  grid-template-columns: 72rem 1.5fr;
  gap: 2rem;

  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: 60rem 1fr;
  }

  @media only screen and (max-width: $bp-900) {
    grid-template-columns: 100%;
  }

  // &__search {
  // 	grid-column: 1/3;
  // 	grid-row: 1/2;
  // 	justify-self: center;
  // }

  &__ql {
    width: 100%;
    border-radius: 2rem;
    background-color: var(--color-white);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    padding: 3.5rem 2rem;
    height: fit-content;
    align-self: center;

    @media only screen and (max-width: $bp-900) {
      width: 95%;
      justify-self: center;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      box-sizing: border-box;
      gap: 2rem;
      margin-top: 3rem;
    }
  }

  &__multiple {
    display: flex;
    flex-direction: column;
    border-radius: 2rem;
    background-color: var(--color-white);
    padding: 2rem;
  }

  &__metrics {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: $bp-1000) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: $bp-900) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: $bp-600) {
      grid-template-columns: repeat(2, 1fr);
    }

    // &_paid {
    //   background-color: var(--color-green);
    //   color: var(--color-white);
    // }

    // &_unpaid {
    //   background-color: var(--color-error);
    //   color: var(--color-white);
    // }

    // &_partPaid {
    //   background-color: var(--color-yellow);
    // }

    // &_vat {
    //   background-color: var(--color-light-red);
    // }

    // &_discount {
    //   background-color: var(--color-primary-7);
    // }

    // &_due {
    //   background-color: var(--color-error);
    //   color: var(--color-white);
    // }

    // &_owe {
    //   background-color: var(--color-error);
    //   color: var(--color-white);
    // }
  }
}

.searchInMiddle {
  display: flex;
  width: 100%;
  justify-content: center;
}

.invoice {
  &__list {
    margin-top: 2rem;
    display: flex;
    position: relative;
  }

  &__beside {
    display: flex;
    justify-content: space-between;
    width: 38rem;
    flex-wrap: wrap;
  }

  &__checks {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 2rem 0;
    width: 38rem;

    &_group {
      align-self: center;
      width: 100%;
    }
  }
}

// .status {
// 	content: none;
// 	width: 50%;
// 	border-radius: 1rem;
// 	background-color: var(--color-yellow);
// 	justify-self: center;
// 	align-self: center;
// 	padding: 3rem;
// 	display: block;
// }

//Table styles start

.table {
  width: 100%;
  max-width: 100%;
  border: none;
  border-collapse: collapse;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--default-font-small);

  th,
  td {
    padding: 2.2rem 0;
  }

  th {
    font-weight: 700;
  }

  tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 7rem 1fr 1fr;
    padding: 0 3rem;

    @media only screen and (max-width: $bp-600) {
      grid-template-columns: 20% 50% 30%;
    }
  }

  tbody {
    & > :not(:last-child) {
      margin-bottom: 1.5rem;
    }

    tr {
      background-color: var(--color-primary-3);
      border-radius: 1.3rem;

      &:nth-child(even) {
        background-color: var(--color-primary-7);
      }

      & > :last-child {
        display: flex;
        justify-content: space-between;
        font-size: var(--default-font-size);
        @media only screen and (max-width: $bp-600) {
          font-size: var(--default-font-small);
        }

        & > * {
          cursor: pointer;
          &:hover {
            color: var(--color-primary);
          }
        }
      }

      & > :nth-child(5) {
        // content: none;
        // width: 3rem;
        // height: 3rem;
        // border-radius: 0.8rem;

        // justify-self: center;
        // align-self: center;
        // padding: 0;
        // margin-left: -2rem;

        font-size: 3rem;
        position: relative;

        span {
          font-size: 1.2rem;
          width: fit-content;
          background-color: rgba(0, 0, 0, 0.75);
          color: var(--color-white);
          position: absolute;
          padding: 0.5rem;
          left: -1.6rem;
          border-radius: 0.5rem;
          text-align: center;
          opacity: 0;
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }
}

.notPaid {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-error);
  color: var(--color-white);
  margin-bottom: 1rem;
  width: 83%;
  // position: relative;
  // top: 1rem;
}
.draft {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-error);
  color: var(--color-white);
  margin-bottom: 1rem;
  width: 83%;
  position: relative;
  top: 1rem;
}

.partPaid {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-yellow);
  color: var(--color-dark);
  margin-bottom: 1rem;
  width: 83%;
}
.expired {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-yellow);
  color: var(--color-dark);
  margin-bottom: 1rem;
  width: 83%;
}

.paid {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-green);
  color: var(--color-white);
  margin-bottom: 1rem;
  width: 83%;
}
.ordered {
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  font-size: 1rem;
  border-radius: 1rem;
  background-color: var(--color-green);
  color: var(--color-white);
  margin-bottom: 1rem;
  width: 83%;
}

.minMenu {
  color: var(--color-primary);
  cursor: pointer;
  font-size: 2rem;
}
//Table styles end

.sideButton {
  display: flex;
  margin-top: 3rem;
}

//Summary Styles
.summary {
  width: 42rem;
  margin-top: 2rem;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__initials {
    display: flex;
    background-color: var(--color-fash-primary);
    font-size: var(--default-font-h3);
    font-weight: 700;
    width: 8rem;
    height: 8rem;
    border-radius: 8rem;
    justify-content: center;
    align-items: center;
  }

  &__edit {
    display: flex;
    align-items: flex-end;
    color: var(--color-dark);
    font-size: var(--default-font-small);
    cursor: pointer;
    margin-bottom: 3rem;

    & > :last-child {
      font-size: 3rem;
      color: var(--color-fash-primary-3);
    }

    &:hover,
    &:focus {
      color: var(--color-fash-primary-2);
      font-weight: 700;
    }
  }

  &__line {
    border-top: 1px solid var(--color-dark);
    margin: 2rem 0;
  }

  &__dates {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    & > :not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__total {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  &__account {
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    gap: 1rem;
    margin: 2rem 0;
  }

  &__button {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin-top: 2rem;
  }
}

//Errors

.error {
  font-size: var(--default-font-small);
  color: var(--color-error);
  padding-left: 2rem;
  font-weight: 400;
  margin: 1rem 0;
}

.select {
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
  font-size: var(--default-font-small);
  width: 38rem;

  margin-bottom: 3rem;
  cursor: pointer;

  & > * {
    padding: 1rem 0 1rem 0;
    text-align: center;
  }

  &__invoice {
    background-color: var(--color-primary);
    color: var(--color-white);
    border-radius: 1rem 0 0 1rem;
    transition: all 0.4s;

    &_b {
      border: 1px solid var(--color-primary);
      border-radius: 1rem 0 0 1rem;
      transition: all 0.4s;
    }
  }

  &__receipt {
    border: 1px solid var(--color-primary);
    border-radius: 0 1rem 1rem 0;
    transition: all 0.4s;

    &_b {
      background-color: var(--color-primary);
      color: var(--color-white);
      border-radius: 0 1rem 1rem 0;
      transition: all 0.4s;
    }
  }
}

.selectCurrency {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--default-font-small);
  position: relative;
  margin-left: 2rem;

  &__text {
    display: flex;
    margin-left: 1rem;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    background-color: #f2f0f0;
    color: var(--color-primary);
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    border: solid 1px var(--color-primary-4);
    cursor: pointer;
    transform: rotateZ(0);
    transition-duration: 500ms;

    &:hover {
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }

    &_active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      background-color: #f2f0f0;
      color: var(--color-primary);
      width: 5rem;
      height: 5rem;
      border-radius: 5rem;
      border: solid 1px var(--color-primary-4);
      cursor: pointer;
      transition-duration: 500ms;
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 5rem;
    background-color: #f2f0f0;
    color: var(--color-dark);
    border-radius: 1rem;
    box-shadow:
      10px 10px 45px #bebebe,
      -15px -15px 30px #ffffff;
    position: absolute;
    top: 6rem;
    z-index: 30;
    border: solid 1px var(--color-primary-4);

    &_active {
      background-color: var(--color-primary-7);
      color: var(--color-primary);
      font-weight: 700;
    }

    li {
      list-style: none;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1rem 0;
      box-sizing: border-box;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-7);
        color: var(--color-primary);
        font-weight: 700;
      }
    }
  }
}

.main {
  display: grid;
  grid-template-columns: 72rem 1fr;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 2rem;

  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: 56rem 1fr;
  }

  @media only screen and (max-width: $bp-700) {
    grid-template-columns: 100%;
  }

  &_nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding: 0 2rem;
    // border-top: solid 1px var(--color-primary);
    background-color: var(--color-white);
    border-bottom: solid 1px var(--color-dark);
    border-radius: 1rem 1rem 0 0;

    & > :not(:last-child) {
      margin-right: 3rem;
    }

    &_group {
      position: relative;
    }

    &_more {
      position: absolute;
      right: 0;
      top: 75%;
      background-color: var(--color-white);
      padding: 1.5rem;
      border-radius: 1rem;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
      z-index: 5;

      & > :not(last-child) {
        margin-bottom: 1rem;
      }
    }

    &_item {
      cursor: pointer;
      padding: 1rem 0;
      box-sizing: border-box;
      font-size: var(--default-font-small);

      // @media only screen and (max-width: $bp-600) {
      // 	width: 47%;
      // }

      &_active {
        cursor: pointer;
        padding: 1rem 0;
        box-sizing: border-box;
        font-size: var(--default-font-small);
        font-weight: 700;
        color: var(--color-fash-primary-3);
        border-bottom: solid 2px var(--color-fash-primary-3);
      }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        font-weight: 700;
        color: var(--color-fash-primary-3);
        border-bottom: solid 2px var(--color-fash-primary-3);
      }
    }
  }

  // & > :nth-child(3) {
  // 	grid-column: 1/3;
  // 	grid-row: 2/3;
  // }
}
