@import '../variables/breakpoints';

.container {
	display: flex;
	position: relative;
	max-width: 100vw;

	&> :first-child {
		align-self: stretch;
	}
}

.ghost {
	width: 20vw;

	@media only screen and (max-width: $bp-1000) {
		width: 0;
	}
}

.appright {
	box-sizing: border-box;
	padding: 3rem 3rem;
	width: 80vw;

	@media only screen and (max-width: $bp-1000) {
		width: 100%;
	}


}

.topside {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2rem;

	&_1 {
		display: flex;
		align-items: center;

		&> :not(:last-child) {
			margin-right: 3rem;
		}
	}

	&_welcome {
		font-size: var(--default-font-small);
		line-height: 1.8rem;
	}

	&_name {
		color: var(--color-primary);
		font-size: var(--default-font-subtitle);
		font-weight: 700;
		line-height: 3rem;
	}

	&_icon {
		display: flex;
		align-items: center;


		@media only screen and (max-width: $bp-500) {
			margin-bottom: 1rem;
		}

		&_main {
			display: flex;
			align-items: center;

			@media only screen and (max-width: $bp-500) {
				flex-direction: column-reverse;
			}
		}
	}

	&_tier {
		box-sizing: border-box;
		font-size: 1rem;
		padding: 0.7rem;
		color: var(--color-white);
		background-color: var(--color-error);
		border-radius: 1rem;
		margin-right: 1rem;


		@media only screen and (max-width: $bp-500) {
			margin-right: 2.7rem;
		}
	}

	h3 {
		font-size: var(--default-font-h3);
		line-height: 3.6rem;

		@media only screen and (max-width: $bp-600) {
			font-size: var(--default-font-subtitle);
		}
	}
}

.menuIcon {
	font-size: 3rem;
	color: var(--color-primary);
	cursor: pointer;

	&:focus,
	&:hover {
		color: var(--color-primary-4);
	}
}

.mobileSidebar {
	width: 100vw;
	height: 100vh;
	background-color: rgba(#2a2828, 0.7);
	position: fixed;
	display: grid;
	grid-template-columns: 40vw 60vw;
	z-index: 100;

	@media only screen and (max-width: $bp-600) {
		grid-template-columns: 60vw 40vw;
	}
}