@import '../variables/breakpoints';

.logo {
	height: 4rem;

	&__sm {
		height: 3rem;
	}
}

.searchInMiddle {
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 2rem;
	gap: 2rem;

	@media only screen and (max-width: $bp-900) {
		flex-direction: column;
		align-items: center;

		&_filter {
			order: 2;
		}

		&_search {
			order: 1;
		}
	}
}

.modal {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;

	&__w38 {
		min-width: 38rem;
		max-width: 100%;
	}

	&__beside {
		display: flex;
		width: 38rem;
		justify-content: space-between;

		&__grid {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 2rem;
			margin-bottom: 2rem;
		}
	}

	&__checks {
		display: flex;
		justify-content: space-between;
		margin: 1rem 0 2rem 0;

		&_group {
			align-self: center;
		}
	}
}

.whiteTable {
	width: 100%;
	// max-height: 100vh;
	// margin-top: 3rem;
	font-size: 1.4rem;
	overflow-y: auto;
	overflow-x: auto;

	&::-webkit-scrollbar:horizontal {
		width: 0;
		height: 0.7rem;
		background: var(--color-white);
	}

	&::-webkit-scrollbar-thumb:horizontal {
		background: var(--color-grey);
		border-radius: 2rem;
	}

	&::-webkit-scrollbar {
		width: 1rem;
		background-color: var(--color-white);
		border-radius: 0 3rem 3rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey);
		border-radius: 3rem;
	}

	table {
		width: 100%;
		max-width: 100%;
		border: none;
		border-collapse: collapse;
		box-sizing: border-box;
		text-align: left;
		// margin-top: 2rem;

		tr {
			padding: 0 1rem;
			align-items: center;
		}

		th,
		td {
			padding: 1.2rem 2rem;
		}

		th {
			font-weight: 500;
		}

		thead {

			padding-bottom: 2rem;
			border-bottom: 1px solid var(--color-grey);
			// margin-bottom: 2rem;
			// tr {
			// 	background-color: var(--color-dark);
			// 	border-radius: 1rem;
			// 	color: var(--color-white);
			// 	position: sticky;
			// 	top: 0;
			// 	z-index: 50;
			// }
		}

		tbody {
			tr {
				background-color: var(--color-white);

				// &:nth-child(even) {
				// 	background-color: var(--color-table-grey);
				// }
			}
		}

		.action {
			&__text {
				font-weight: 600;
				color: var(--color-primary);
				text-decoration: underline;
				cursor: pointer;
			}

			&__icons {
				width: 100%;
				display: flex;
				justify-content: space-between;
				font-size: 2.4rem;

				&>* {
					cursor: pointer;
					position: relative;

					&:hover {
						color: var(--color-primary);
					}

					&> :last-child {
						opacity: 0;

						&:hover {
							opacity: 1;
						}
					}
				}

				&> :not(last-child) {
					margin-right: 0.5rem;
				}
			}

			&__details {
				font-size: 1rem;
				color: var(--color-white);
				padding: 0.8rem;
				background-color: rgba(#003c97, 0.7);
				border-radius: 1rem;
				position: absolute;
				top: -0.7rem;
				left: -1.5rem;
			}
		}

		img {
			width: 5rem;
		}
	}
}

.status {
	display: flex;
	font-weight: 700;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	width: 14rem;
	font-size: 1.2rem;
	padding: 0.5rem;
	height: fit-content;

	&__th {
		display: flex;
		justify-content: center;
		width: 14rem;
	}

	&__dark__yellow {
		color: var(--color-dark-yellow);
		background-color: var(--color-light-yellow);
		border: 0.5px solid var(--color-dark-yellow);
	}

	&__green {
		color: var(--color-green);
		background-color: var(--color-light-green);
		border: 0.5px solid var(--color-green);
	}

	&__green2 {
		color: var(--color-green);
		background-color: var(--color-green-3);
		border: 0.5px solid var(--color-green);
	}

	&__red {
		color: var(--color-error);
		background-color: var(--color-light-red);
		border: 0.5px solid var(--color-error);
	}

	&__grey {
		color: var(--color-grey-2);
		background-color: var(--color-light-grey);
		border: 0.5px solid var(--color-grey-2);
	}
}

.filter {
	&_content {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&> :not(last-child) {
			margin-right: 1rem;
		}
	}
}

.noInsights {
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--color-error);
	font-size: var(--default-font-subtitle);
	text-align: center;
	height: 70%;
}

.PhoneInputInput {
	font-size: 1.8rem;
	font-family: inherit;
	color: var(--color-dark);
	padding-left: 2rem;
	height: 4.8rem;
	margin-top: 0.4rem;
	border-radius: 0.8rem;
	background-color: var(--color-white);
	border: 1px solid var(--color-dark);
	display: block;
	box-sizing: border-box;
	outline: none;

	&:focus,
	&:focus-visible,
	&:focus-within {
		color: var(--color-dark);
		border: 2px solid var(--color-dark);
		box-shadow: inset 2px 2px 3px #cad2e1;
	}
}

.container {
	margin-left: auto;
	margin-right: auto;
	max-width: 130rem;
	width: 100%;
	padding: 0 2rem;
}

.fullWidth {
	&__border {
		border-bottom: 1px solid var(--color-grey-3);
	}
}

.closing {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	gap: 2rem;
	align-items: center;
	justify-content: center;
}