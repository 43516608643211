@import "../variables/breakpoints";

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--color-light);
  align-items: center;
  width: 100vw;
  height: 100%;
  padding: 3rem 8rem;

  @media only screen and (max-width: $bp-700) {
    padding: 3rem;
  }

  &> :first-child {
    align-self: stretch;
  }
}

.topside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  &_1 {
    display: flex;
    align-items: center;

    &> :not(:last-child) {
      margin-right: 3rem;
    }
  }

  &_welcome {
    font-size: var(--default-font-small);
    line-height: 1.8rem;
  }

  &_name {
    color: var(--color-primary);
    font-size: var(--default-font-subtitle);
    font-weight: 700;
    line-height: 3rem;
  }

  &_icon {
    display: flex;
    align-items: center;

    @media only screen and (max-width: $bp-500) {
      margin-bottom: 1rem;
    }

    &_main {
      display: flex;
      align-items: center;

      @media only screen and (max-width: $bp-500) {
        flex-direction: column-reverse;
      }
    }
  }
}

.banner {
  display: grid;
  grid-template-columns: 1fr 40%;
  gap: 2rem;
  padding: 2rem;
  align-items: center;
  width: 100%;
  background-image: url("../../img/bg_img.svg");
  background-size: cover;
  box-sizing: border-box;

  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: 40% 1fr;
  }

  @media only screen and (max-width: $bp-800) {
    grid-template-columns: 1fr;
  }

  &__walletBg {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background-color: var(--color-white);
    box-sizing: border-box;
    padding: 2rem;
  }
}

.balance {
  &__side {
    display: flex;
    justify-content: space-between;
  }

  &__amount {
    display: flex;
    flex-direction: column;
  }

  &__btns {
    padding-left: 1rem;
  }
}

.selectCurrency {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--default-font-small);
  position: relative;
  margin-left: 2rem;

  &__text {
    display: flex;
    margin-left: 1rem;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    background-color: #f2f0f0;
    color: var(--color-primary);
    width: 3rem;
    height: 3rem;
    border-radius: 5rem;
    border: solid 1px var(--color-primary-4);
    cursor: pointer;
    transform: rotateZ(0);
    transition-duration: 500ms;

    &:hover {
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }

    &_active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      background-color: #f2f0f0;
      color: var(--color-primary);
      width: 3rem;
      height: 3rem;
      border-radius: 5rem;
      border: solid 1px var(--color-primary-4);
      cursor: pointer;
      transition-duration: 500ms;
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 5rem;
    background-color: #f2f0f0;
    color: var(--color-dark);
    border-radius: 1rem;
    box-shadow:
      10px 10px 45px #bebebe,
      -15px -15px 30px #ffffff;
    position: absolute;
    top: 6rem;
    z-index: 30;
    border: solid 1px var(--color-primary-4);

    &_active {
      background-color: var(--color-primary-7);
      color: var(--color-primary);
      font-weight: 700;
    }

    li {
      list-style: none;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1rem 0;
      box-sizing: border-box;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-7);
        color: var(--color-primary);
        font-weight: 700;
      }
    }
  }
}

.subImg {
  width: 28rem;
  margin: 4rem 0;
}

.logo {
  height: 3.8rem;
  margin-bottom: 3rem;
}

.btns {
  display: grid;
  grid-template-columns: 1000%;
  gap: 3rem;
  padding-bottom: 4rem;

  // @media only screen and (max-width: $bp-900) {
  //   grid-template-columns: 1fr;
  //   gap: 3rem;
  // }

  &__btn {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    border: 1px solid var(--color-primary);
    border-radius: 0.8rem;
    padding: 3rem;
    width: 40rem;
    cursor: pointer;
    transition: all 0.2s;

    @media only screen and (max-width: $bp-900) {
      width: 38rem;
    }

    &:hover {
      background-color: var(--color-primary-3);
      border: 2px solid var(--color-primary);
      color: var(--color-primary);
      font-weight: 700;
      transition: all 0.2s;
    }
  }
}