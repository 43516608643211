@import '../variables/breakpoints';

.modal {
	&__img {
		height: 7.5rem;
		margin-bottom: 3rem;
	}
}

.main {
	&_nav {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: 2rem;
		padding: 0 2rem;
		// border-top: solid 1px var(--color-primary);
		background-color: var(--color-white);
		border-bottom: solid 1px var(--color-dark);
		border-radius: 1rem 1rem 0 0;

		& > :not(:last-child) {
			margin-right: 1rem;
		}

		&_group {
			position: relative;
		}

		&_more {
			position: absolute;
			right: 0;
			top: 75%;
			background-color: var(--color-white);
			padding: 1.5rem;
			border-radius: 1rem;
			box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
			z-index: 5;

			& > :not(last-child) {
				margin-bottom: 1rem;
			}
		}

		&_item {
			cursor: pointer;
			padding: 1rem 0;
			box-sizing: border-box;
			font-size: var(--default-font-small);

			// @media only screen and (max-width: $bp-600) {
			// 	width: 47%;
			// }

			&:hover,
			&:focus,
			&:focus-visible,
			&:focus-within {
				font-weight: 700;
				color: var(--color-primary);
				border-bottom: solid 2px var(--color-primary);
			}
		}
	}

	&__metrics {
		box-sizing: border-box;
		padding: 3.5rem 2rem;
		width: 100%;
		display: grid;
		box-sizing: border-box;
		grid-template-columns: repeat(3, 1fr);
		gap: 2rem;

		@media only screen and (max-width: $bp-600) {
			grid-template-columns: repeat(2, 50%);
		}

		&_active {
			background-color: var(--color-green);
			color: var(--color-white);
		}
		&_suspended {
			background-color: var(--color-yellow);
		}
		&_terminated {
			background-color: var(--color-error);
			color: var(--color-white);
		}
	}

	&__dropdowns {
		margin-top: 2rem;
		display: flex;
		justify-content: center;
		width: 100%;

		@media only screen and (max-width: $bp-700) {
			flex-direction: column;
			align-items: center;
		}

		& > :not(last-child) {
			margin-right: 2rem;
			@media only screen and (max-width: $bp-700) {
				margin-right: none;
			}
		}
	}
}

.payroll {
	&_table {
		width: 100%;
		margin-top: 3rem;
		font-size: var(--default-font-small);

		table {
			width: 100%;
			max-width: 100%;
			border: none;
			border-collapse: collapse;
			box-sizing: border-box;
			text-align: left;

			tr {
				display: grid;
				grid-template-columns: 5% 18% 15% repeat(2, 12%) 9% 20% 10%;
				padding: 0 1rem;
				align-items: center;

				@media only screen and (max-width: $bp-800) {
					grid-template-columns: 15% 40% 30% 15%;
				}

				// @media only screen and (max-width: $bp-600) {
				// 	grid-template-columns: 1fr;
				// }
			}

			th,
			td {
				padding: 2.2rem 2rem;
			}

			th {
				font-weight: 700;
			}

			td {
				cursor: pointer;
			}

			tbody {
				& > :not(:last-child) {
					margin-bottom: 1.5rem;
				}

				tr {
					background-color: var(--color-primary-3);
					border-radius: 1.3rem;

					&:nth-child(even) {
						background-color: var(--color-primary-7);
						border-radius: 1.3rem;
					}
				}
			}
		}
	}
}

.status {
	&__pending {
		color: var(--color-yellow-3);
	}
	&__paid {
		color: var(--color-green);
	}
	&__failed {
		color: var(--color-error);
	}
}
.employee {
	&__list {
		width: 38rem;
		margin-top: 5rem;
		li {
			list-style: none;
			cursor: pointer;

			&:hover {
				font-weight: 700;
				color: var(--color-primary);
			}
		}

		&_line {
			border-top: 1px solid var(--color-primary);
			margin: 2rem 0;
		}
	}

	&__avatar {
		width: 9rem;

		&_container {
			width: 10rem;
			height: 10rem;
			border-radius: 7rem;
			border: 1px solid var(--color-primary);
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 3rem 0;
		}
	}

	&__details {
		width: 38rem;
	}

	&__editButton {
		position: relative;
		right: 17rem;
		display: flex;
		font-size: 2.5rem;
		align-items: center;
		cursor: pointer;

		& > :not(:last-child) {
			margin-right: 1rem;
		}
	}
	&__terminate {
		display: flex;
		flex-direction: column;
		width: 38rem;

		& > :not(:last-child) {
			margin-bottom: 1rem;
		}
	}
	&_table {
		width: 100%;
		margin-top: 3rem;
		font-size: var(--default-font-small);

		table {
			width: 100%;
			max-width: 100%;
			border: none;
			border-collapse: collapse;
			box-sizing: border-box;
			text-align: left;

			tr {
				display: grid;
				grid-template-columns: 16% repeat(3, 15%) 15% 10% 10% 5% 15%;
				padding: 0 1rem;
				align-items: center;

				@media only screen and (max-width: $bp-800) {
					grid-template-columns: repeat(2, 40%) 20%;
				}

				// @media only screen and (max-width: $bp-600) {
				// 	grid-template-columns: 1fr;
				// }
			}

			th,
			td {
				padding: 2.2rem 1rem;
			}

			th {
				font-weight: 700;
			}

			td {
				cursor: pointer;
			}

			tbody {
				& > :not(:last-child) {
					margin-bottom: 1.5rem;
				}

				tr {
					background-color: var(--color-primary-3);
					border-radius: 1.3rem;

					&:nth-child(even) {
						background-color: var(--color-primary-7);
						border-radius: 1.3rem;
					}

					& > :last-child {
						font-weight: 700;
					}
				}
			}
		}
	}
}

.crumbs {
	display: flex;
	margin-top: 2rem;
	margin-bottom: 1rem;
	& > :not(:last-child) {
		margin-right: 2rem;
	}

	&__main {
		content: '';
		height: 2rem;
		width: 2rem;
		border-radius: 2rem;
		background-color: var(--color-grey);
	}
	&__active {
		width: 5rem;
		background-color: var(--color-primary);
	}
}

.checks {
	display: flex;
	justify-content: space-between;
	margin-bottom: 2rem;
	& > :not(:last-child) {
		margin-right: 1rem;
	}

	&_group {
		width: 38rem;
	}
}

.gp {
	width: 40rem;

	&__vb {
		position: relative;
		left: 20rem;
	}

	&__vps {
		position: relative;
		left: 16.5rem;
	}

	&__top {
		display: flex;
		justify-content: space-between;
		width: 44rem;
		margin-bottom: 3rem;
	}

	table {
		width: 100%;
		max-width: 100%;
		border: none;
		border-collapse: collapse;
		box-sizing: border-box;
		text-align: left;
		font-size: var(--default-font-small);

		tr {
			display: grid;
			grid-template-columns: 2.6fr repeat(2, 1.2fr);
			align-items: center;
			border-bottom: 1px solid var(--color-primary);

			// @media only screen and (max-width: $bp-900) {
			// 	grid-template-columns: 2.3fr 1.6fr 2fr 0.9fr 0.2fr;
			// }

			// @media only screen and (max-width: $bp-600) {
			// 	grid-template-columns: 1fr;
			// }
		}

		th {
			font-weight: 700;
			color: var(--color-primary);
		}

		td {
			cursor: pointer;
		}

		tbody {
			& > :first-child {
				margin-top: 1rem;
			}
			& > * {
				padding: 1rem 0;
			}

			tr {
				& > :first-child {
					font-weight: 700;
				}
			}
		}
	}
}

.ps {
	width: 40rem;

	&__breakdown {
		width: 38rem;
		&_sm {
			font-size: var(--default-font-small);
		}

		&_bdr {
			border-bottom: 1px solid var(--color-primary);
		}

		&_total {
			font-size: var(--default-font-small);
		}

		table {
			width: 100%;
			max-width: 100%;
			border: none;
			border-collapse: collapse;
			box-sizing: border-box;
			text-align: left;
			font-size: var(--default-font);

			thead,
			tbody {
				tr {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					align-items: center;

					& > :first-child {
						justify-self: start;
						font-weight: 400;
					}
					& > :last-child {
						justify-self: end;
						font-weight: 700;
					}
				}

				& > * {
					padding: 1rem 0;
				}
			}

			th {
				color: var(--color-primary);
			}
		}
	}
}

.mp {
	width: 38rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.success {
	height: 27rem;
	margin-bottom: 3rem;
}

.simpleTable {
	width: 100%;
	margin-top: 3rem;
	font-size: 1.2rem;

	table {
		width: 100%;
		max-width: 100%;
		border: none;
		border-collapse: collapse;
		box-sizing: border-box;
		text-align: left;
		margin-top: 2rem;

		tr {
			padding: 0 1rem;
			align-items: center;
		}

		th,
		td {
			padding: 1.2rem 2rem;
		}

		th {
			font-weight: 700;
		}

		thead {
			margin-bottom: 2rem;
			tr {
				background-color: var(--color-dark);
				border-radius: 1rem;
				color: var(--color-white);
			}
		}

		tbody {
			tr {
				background-color: var(--color-white);

				&:nth-child(even) {
					background-color: var(--color-table-grey);
				}
			}
		}

		.pending {
			display: inline-flex;
			justify-content: center;
			line-height: 1;
			font-size: 1rem;
			border-radius: 1rem;
			background-color: var(--color-yellow);
			color: var(--color-dark);
			margin-top: 0.7rem;
			width: 100%;
		}

		.paid {
			display: inline-flex;
			justify-content: center;
			line-height: 1;
			font-size: 1rem;
			border-radius: 1rem;
			background-color: var(--color-success);
			color: var(--color-white);
			margin-top: 0.7rem;
			width: 100%;
		}

		.failed {
			display: inline-flex;
			justify-content: center;
			line-height: 1;
			font-size: 1rem;
			border-radius: 1rem;
			background-color: var(--color-error);
			color: var(--color-white);
			margin-top: 0.7rem;
			width: 100%;
		}

		.others {
			display: inline-flex;
			justify-content: center;
			line-height: 1;
			font-size: 1rem;
			border-radius: 1rem;
			background-color: var(--color-dark);
			color: var(--color-white);
			margin-top: 0.7rem;
			width: 100%;
		}

		.change {
			display: inline-flex;
			justify-content: center;
			line-height: 1;
			font-size: 1rem;
			border-radius: 1rem;
			background-color: var(--color-primary);
			color: var(--color-white);
			margin-top: 0.7rem;
			width: 100%;
		}

		.action__text {
			font-weight: 600;
			color: var(--color-primary);
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.filter {
	position: absolute;
	width: fit-content;
	right: 0;
	top: 75%;
	background-color: var(--color-white);
	padding: 1.5rem;
	border-radius: 1rem;
	box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
	z-index: 3;
	@media only screen and (max-width: $bp-500) {
		top: 95%;
	}
	// clip-path: polygon(
	// 	48% 10%,
	// 	50% 0,
	// 	52% 10%,
	// 	100% 10%,
	// 	100% 100%,
	// 	0 100%,
	// 	0 10%
	// );

	&_title {
		width: 100%;
		font-size: var(--default-font-small);
		background-color: var(--color-light);
		padding: 1rem;
		& > :not(:last-child) {
			margin-right: 1.5rem;
		}
	}

	&_group {
		display: flex;
		flex-wrap: wrap;
		// margin: 2rem 0;
		& > :not(:last-child) {
			margin-right: 1rem;
		}
	}

	&_item {
		font-size: 1.2rem;
		padding: 0.5rem;
		border: 1px solid var(--color-dark);
		border-radius: 0.8rem;
		cursor: pointer;
		margin-bottom: 1rem;
	}
	&_selectedItem {
		font-size: 1.2rem;
		padding: 0.5rem;
		border: 1px solid var(--color-dark);
		border-radius: 0.8rem;
		cursor: pointer;
		color: var(--color-white);
		font-weight: 700;
		background-color: var(--color-dark);
		margin-bottom: 1rem;
	}
}

.cpt {
	display: flex;
	font-size: var(--default-font-small);
	width: 38rem;
	overflow-x: scroll;
	&::-webkit-scrollbar {
		width: 1rem;
		background-color: var(--color-white);
		border-radius: 0 1rem 1rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey);
		border-radius: 1rem;
	}

	&_action {
		font-weight: 700;
		color: var(--color-primary);
		cursor: pointer;
		text-decoration: underline;
	}

	tr {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 2rem;
	}

	thead {
		background-color: var(--color-grey-3);
		width: 19rem;
		position: fixed;
		z-index: 2;
	}
	tbody {
		position: relative;
		left: 50%;
		display: flex;
		tr {
			background-color: var(--color-light);
			width: 19rem;

			&:nth-child(even) {
				background-color: var(--color-white);
			}
		}
	}
}

.beneficiary {
	display: grid;
	grid-template-columns: 1fr 1fr;
	box-sizing: border-box;
	font-size: var(--default-font-small);
	width: 38rem;

	margin-bottom: 3rem;
	cursor: pointer;

	& > * {
		padding: 1rem 0 1rem 0;
		text-align: center;
	}

	&__saved {
		background-color: var(--color-primary);
		color: var(--color-white);
		border-radius: 1rem 0 0 1rem;
		transition: all 0.4s;

		&_b {
			border: 1px solid var(--color-primary);
			border-radius: 1rem 0 0 1rem;
			transition: all 0.4s;
		}
	}

	&__new {
		border: 1px solid var(--color-primary);
		border-radius: 0 1rem 1rem 0;
		transition: all 0.4s;

		&_b {
			background-color: var(--color-primary);
			color: var(--color-white);
			border-radius: 0 1rem 1rem 0;
			transition: all 0.4s;
		}
	}
}

.unit {
	&_group {
		width: 38rem;
		display: grid;
		grid-template-columns: repeat(2, 50%);
		gap: 2rem;
	}

	&_card {
		background-color: var(--color-primary-3);
		padding: 2rem;
		box-sizing: border-box;
		border-radius: 2rem;
		font-size: 4rem;
		display: flex;
		align-items: center;
		flex-direction: column;
		color: var(--color-primary);
		cursor: pointer;
		transition: all 0.4s;

		&:hover {
			background-color: var(--color-primary-7);
		}
	}
}
