@import '../variables/breakpoints';

.ratings {
    display: flex;
    gap: 1rem;
    cursor: pointer;
    align-items: center;
    max-height: 6vh;
    margin: 4rem 0;

    &_active {
        padding: 1rem;
        transform: translateY(-1rem);
        transition: 0.5s;
    }
}