.icon {
	font-size: 2.5rem;

	&>* {
		margin-right: 2rem;
		cursor: pointer;

		&:hover {
			color: var(--color-fash-primary-3);
		}
	}
}