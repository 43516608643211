@import '../variables/breakpoints';

.main {
	display: grid;
	grid-template-columns: 1fr 3fr;
	column-gap: 3rem;
	margin-top: 5rem;

	@media only screen and (max-width: $bp-800) {
		grid-template-columns: 100%;
		row-gap: 3rem;
	}
}

.pb {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	& > :not(:last-child) {
		margin-bottom: 1rem;
	}

	@media only screen and (max-width: $bp-800) {
		flex-direction: row;
	}

	&__item {
		background-color: var(--color-primary-7);
		width: 100%;
		height: 17rem;
		border-radius: 2rem;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media only screen and (max-width: $bp-800) {
			width: 30%;
		}

		@media only screen and (max-width: $bp-600) {
			height: 12rem;
		}
		@media only screen and (max-width: $bp-400) {
			height: 10rem;
		}
	}

	&__image {
		width: 6rem;
		margin-bottom: 1.5rem;

		@media only screen and (max-width: $bp-600) {
			width: 4rem;
		}
		@media only screen and (max-width: $bp-400) {
			width: 3rem;
		}
	}
}

.history {
	display: flex;
	flex-direction: column;
	background-color: var(--color-white);
	box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
	box-sizing: border-box;
	padding: 3rem 2rem;
	border-radius: 2rem;
	min-height: 30vh;
	height: 80vh;
	overflow-y: scroll;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		width: 1rem;
		background-color: var(--color-white);
		border-radius: 0 3rem 3rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey);
		border-radius: 3rem;
	}

	&__top {
		margin-bottom: 2rem;
		display: flex;		
		align-items: center;
		justify-content: space-between;
		@media only screen and (max-width: $bp-600) {
			flex-direction: column;
		}

		& > :not(:last-child) {
			margin-right: 3rem;
			@media only screen and (max-width: $bp-600) {
				margin-right: 0;
				margin-bottom: 2rem;
			}
		}
	}

	&__date {
		background: var(--color-dark);
		border-radius: 1rem;
		width: 12.5rem;
		padding: 1rem;
		text-align: center;
		margin: 0 0 2rem 2rem;
		font-weight: 700;
		font-size: var(--default-font-small);
		line-height: 1.7rem;
		color: var(--color-white);
	}
}

//Table styles start

.table {
	width: 100%;
	max-width: 100%;
	border: none;
	border-collapse: collapse;
	box-sizing: border-box;
	text-align: left;
	font-size: var(--default-font-small);
	overflow-x: scroll;
	overflow-y: scroll;

	tr {
		
		padding: 0 2rem;		
		@media only screen and (max-width: $bp-600) {
			padding: 0 1.5rem;
		}

		// & > :nth-child(1) {
		// 	@media only screen and (max-width: $bp-500) {
		// 		grid-row: 1/3;
		// 		grid-column: 1/2;
		// 	}
		// }

		// & > :nth-child(3) {
		// 	@media only screen and (max-width: $bp-500) {
		// 		grid-row: 2/3;
		// 		grid-column: 2/3;
		// 	}
		// }

		img {
			width: 4.5rem;
			border-radius: 4.5rem;
		}
	}

	th,
	td {
		padding: 1rem;
	}

	th {
		font-weight: 700;
	}

	td {
		cursor: pointer;
	}

	tbody {
		tr {
			background-color: var(--color-grey-3);

			&:nth-child(even) {
				background-color: var(--color-light);
			}
		}
	}

	&__icon {
		width: 4.5rem;
		border-radius: 4.5rem;

		@media only screen and (max-width: $bp-600) {
			width: 2.5rem;
		}
	}

	&__title {
		color: var(--color-primary);
	}

	&__amount {
		color: var(--color-error);
	}
}

//otpForm
.pin {
	&__label {
		font-size: var(--default-font-small);
		font-family: inherit;
		padding-left: 4rem;
	}

	&__item {
		margin-left: 2rem;
	}
}

.success {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.summary {
	text-align: start;
	width: 38rem;
}

.error {
	font-size: var(--default-font-small);
	color: var(--color-error);
	padding-left: 2rem;
	font-weight: 400;
	margin: 1rem 0;
}
