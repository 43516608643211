@import "../variables/breakpoints";

.main {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  grid-template-rows: max-content min-content;
  gap: 3rem;

  @media only screen and (max-width: $bp-900) {
    grid-template-rows: repeat(2, max-content) min-content;
  }

  @media only screen and (max-width: $bp-700) {
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, max-content);
  }

  & > :last-child {
    @media only screen and (max-width: $bp-900) {
      grid-column: 1/3;
      grid-row: 2/3;
      margin-bottom: 5rem;
    }

    @media only screen and (max-width: $bp-700) {
      grid-column: 1/2;
      grid-row: 3/4;
    }
  }

  & > :nth-child(3) {
    @media only screen and (max-width: $bp-900) {
      grid-column: 1/3;
    }

    @media only screen and (max-width: $bp-700) {
      grid-column: 1/2;
    }
  }
}

.paystackButton {
  width: 38rem;
  border-radius: 1rem;
  background-color: var(--color-dark);
  color: var(--color-white);
  font-weight: 700;
  box-sizing: border-box;
  padding: 1.9rem 2.2rem;
  justify-content: center;
  margin-top: 2rem;

  &_container {
    display: flex;
    justify-content: center;
  }
}

.wallets {
  display: grid;
  grid-template-columns: 100%;
  gap: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  // overflow-x: scroll;

  // &::-webkit-scrollbar {
  // 	height: 1rem;
  // 	background-color: var(--color-light);
  // 	border-radius: 0 1rem 1rem 0;
  // }

  // &::-webkit-scrollbar-thumb {
  // 	background-color: var(--color-grey);
  // 	border-radius: 1rem;

  // }
}

.lowerHalf {
  display: grid;
  grid-template-columns: 55% 45%;
  gap: 2rem;

  & > :nth-child(2) {
    & > :nth-child(1) {
      margin-bottom: 2rem;
    }
  }

  @media only screen and (max-width: $bp-600) {
    grid-template-columns: 100%;

    & > :nth-child(1) {
      grid-row: 2/3;
    }

    & > :nth-child(2) {
      grid-row: 1/2;
    }
  }
}

.balance {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 3rem;
  border-radius: 1rem;
  background-color: var(--color-table-grey);
  color: var(--color-dark);
  // background-color: var(--color-fash-primary-4);
  // background-image: url("../../img/background.png");
  background-size: cover;
  transition: all 0.4s;
  width: 100%;
  height: fit-content;
  // min-width: 50rem;

  // @media only screen and (max-width: 56.25em) {
  // 	padding: 3rem 2rem;
  // 	min-width: 38rem;
  // }

  &__side {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__amount {
    display: flex;
    flex-direction: column;
  }

  &__btns {
    display: flex;

    & > :not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.card {
  display: flex;
  box-sizing: border-box;
  padding: 2rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  background-size: cover;
  transition: all 0.4s;
  width: 100%;
  height: fit-content;
  gap: 2rem;

  // &_moneyIn {
  //   background-color: var(--color-primary-7);
  // }

  // &_moneyOut {
  //   background-color: var(--color-light-red);
  // }
}

.selectCurrency {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: var(--default-font-small);
  position: relative;
  margin-left: 2rem;

  &__text {
    display: flex;
    margin-left: 1rem;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    background-color: #f2f0f0;
    color: var(--color-fash-primary-2);
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    border: solid 1px var(--color-fash-primary-2);
    cursor: pointer;
    transform: rotateZ(0);
    transition-duration: 500ms;

    &:hover {
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }

    &_active {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 3rem;
      background-color: #f2f0f0;
      color: var(--color-fash-primary-3);
      width: 5rem;
      height: 5rem;
      border-radius: 5rem;
      border: solid 1px var(--color-fash-primary-3);
      cursor: pointer;
      transition-duration: 500ms;
      box-shadow:
        10px 10px 45px #bebebe,
        -15px -15px 30px #ffffff;
      background-color: var(--color-primary-3);
      transform: rotateZ(-180deg);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 5rem;
    background-color: #f2f0f0;
    color: var(--color-dark);
    border-radius: 1rem;
    box-shadow:
      10px 10px 45px #bebebe,
      -15px -15px 30px #ffffff;
    position: absolute;
    top: 6rem;
    z-index: 30;
    border: solid 1px var(--color-fash-primary-3);

    &_active {
      background-color: var(--color-light);
      color: var(--color-fash-primary-3);
      font-weight: 700;
    }

    li {
      list-style: none;
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 1rem 0;
      box-sizing: border-box;
      border-radius: 1rem;
      cursor: pointer;

      &:hover {
        background-color: var(--color-primary-7);
        color: var(--color-primary);
        font-weight: 700;
      }
    }
  }
}

.exchange {
  margin-top: 2rem;
  width: 40rem;
  height: 10rem;
  box-sizing: border-box;
  background-color: var(--color-table-grey-1);
  border-radius: 1rem;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 1rem;
  }

  &__side {
    display: flex;
    align-items: center;
  }

  &__input {
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid var(--color-dark);
    width: 15rem;
  }
}

.pin {
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  padding-left: 2rem;

  &__label {
    font-size: var(--default-font-small);
    font-family: inherit;
    padding-left: 4rem;
  }

  &__group {
    margin: 3rem auto;
  }

  &__item {
    margin-top: 0.5rem;
  }
}
