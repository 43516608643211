@import "./variables/variables";

.label {
  font-size: $default-font-small;
  font-family: inherit;
  padding-left: 2rem;
  margin-bottom: 1rem;
  width: 100%;
  display: flex;
  max-width: 38rem;

  &__wide {
    font-size: $default-font-small;
    font-family: inherit;
    padding-left: 2rem;
    margin-bottom: 1rem;
    padding-left: 2rem;
  }
}

.select {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 3rem;
  color: $color-dark;

  &__options {
    padding: 1.5rem 2rem;
    cursor: pointer;

    &:hover {
      background-color: var(--color-dark);
      color: var(--color-white);
    }

    &_selected {
      // color: $color-grey;
      padding-left: 2rem;
      padding-top: 1.9rem;

      width: 38rem;
      height: 6rem;
      border-radius: 1rem;
      background-color: var(--color-white);
      border: 1px solid $color-dark;
      display: block;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      color: $color-dark;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      order: 0;
      position: relative;

      &::after {
        content: "";
        background: url("../svg/arrowDown.svg");
        background-size: contain;
        background-repeat: no-repeat;

        position: absolute;
        height: 100%;
        width: 1.6rem;
        right: 2rem;
        top: 2.5rem;

        transition: all 0.4s;
      }

      &__wide {
        padding-left: 2rem;
        width: 100%;
      }
    }

    &_active+&_selected::after {
      transform: rotateX(180deg);
      top: -2.5rem;
    }

    &_container {
      background-color: var(--color-white);
      color: $color-dark;
      border: 1px solid $color-dark;
      width: 38rem;
      border-radius: 1rem;
      transition: all 0.4s;
      order: 1;
      max-height: 27rem;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 1rem;
        background-color: var(--color-white);
        border-radius: 0 1rem 1rem 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-grey;
        border-radius: 1rem;
      }

      &__wide {
        width: 60rem;

        @media only screen and (max-width: 31.25em) {
          width: 38rem;
        }
      }
    }

    &_active {
      opacity: 1;
      max-height: 27rem;
      overflow-y: scroll;
    }

    &_label {
      cursor: pointer;
    }
  }
}

.select2 {
  display: flex;
  width: 18rem;
  flex-direction: column;
  margin-top: 3rem;
  color: $color-dark;

  &__options {
    padding: 1.5rem 2rem;
    cursor: pointer;

    &:hover {
      background-color: var(--color-dark);
      color: var(--color-white);
    }

    &_selected {
      // color: $color-grey;
      padding-left: 2rem;
      padding-top: 0.9rem;
      font-size: var(--default-font-small);
      width: 18rem;
      height: 4rem;
      border-radius: 1rem;
      background-color: var(--color-white);
      border: 1px solid $color-dark;
      display: block;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      color: $color-dark;
      margin-top: 0.5rem;
      order: 0;
      position: relative;

      &::after {
        content: "";
        background: url("../svg/arrowDown.svg");
        background-size: contain;
        background-repeat: no-repeat;

        position: absolute;
        height: 100%;
        width: 1.6rem;
        right: 2rem;
        top: 1.5rem;

        transition: all 0.4s;
      }

      &__wide {
        padding-left: 2rem;
        width: 100%;
      }
    }

    &_active+&_selected::after {
      transform: rotateX(180deg);
      top: -2.5rem;
    }

    &_container {
      background-color: var(--color-white);
      color: $color-dark;
      border: 1px solid $color-dark;
      width: 18rem;
      border-radius: 1rem;
      transition: all 0.4s;
      order: 1;
      max-height: 27rem;
      overflow-y: scroll;
      font-size: var(--default-font-small);

      &::-webkit-scrollbar {
        width: 1rem;
        background-color: var(--color-white);
        border-radius: 0 1rem 1rem 0;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-grey;
        border-radius: 1rem;
      }

      &__wide {
        width: 38rem;

        @media only screen and (max-width: 31.25em) {
          width: 18rem;
        }
      }
    }

    &_active {
      opacity: 1;
      max-height: 27rem;
      overflow-y: scroll;
    }

    &_label {
      cursor: pointer;
    }
  }
}

// When form is filled

.filled {
  color: $color-dark;
}

.error {
  font-size: var(--default-font-small);
  color: var(--color-error);
  padding-left: 2rem;
  font-weight: 400;
  margin: 1rem 0;
  max-width: 38rem;
}

// Success
.success {
  &__h3 {
    color: var(--color-success);
    font-size: var(--default-font-h3);
    font-weight: 700;
    line-height: 3.5rem;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;

  &> :not(:last-child) {
    margin-right: 1rem;
  }
}

.singleTag {
  display: flex;
  font-size: var(--default-font-small);
  align-items: center;
  background-color: var(--color-primary-3);
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 0.5rem;

  &> :not(:last-child) {
    margin-right: 0.5rem;
  }


  &:hover {
    color: var(--color-primary);
  }

}