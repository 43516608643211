@import "../variables/breakpoints";

.upper {
  display: grid;
  grid-template-columns: 72rem 1.5fr;
  gap: 2rem;

  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: 60rem 1fr;
  }

  @media only screen and (max-width: $bp-900) {
    grid-template-columns: 100%;
  }

  &_container {
    box-sizing: border-box;
    padding: 2rem;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 2rem;
  }

  &__metrics {
    box-sizing: border-box;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-bottom: 1rem;

    @media only screen and (max-width: $bp-1000) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: $bp-900) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media only screen and (max-width: $bp-600) {
      grid-template-columns: repeat(2, 1fr);
    }

    // &_processing {
    // 	background-color: var(--color-yellow);
    // }

    // &_shipped {
    // 	background-color: var(--color-primary);
    // 	color: var(--color-white);
    // }

    // &_refund {
    // 	background-color: var(--color-error);
    // 	color: var(--color-white);
    // }

    // &_completed {
    // 	background-color: var(--color-green);
    // 	color: var(--color-white);
    // }

    // &_void {
    // 	background-color: var(--color-dark);
    // 	color: var(--color-white);
    // }
  }
}

.searchInMiddle {
  display: flex;
  width: 100%;
  justify-content: center;
}

.invoice {
  &__list {
    margin-top: 2rem;
    display: flex;
    position: relative;
  }

  &__beside {
    display: flex;
    justify-content: space-between;
    width: 38rem;
    flex-wrap: wrap;
  }

  &__checks {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 2rem 0;
    width: 100%;

    &_group {
      align-self: center;
      width: 100%;
    }
  }
}

.more {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: red;
}

.view {
  position: relative;

  &_menu {
    color: var(--color-primary);
    cursor: pointer;
    font-size: 3rem;
    position: absolute;
    left: 20.5rem;
    top: 6rem;
  }

  &_customerMenu {
    right: -20rem;
    top: 6rem;
  }

  &_content {
    width: 35rem;
    margin-top: 3rem;
  }
}

.sum {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
}

.line {
  border: 0.1px solid var(--color-grey);
  margin: 2rem 0;
}

.refunded {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-error);
  color: var(--color-white);
  width: 9rem;
}

.processing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-yellow);
  color: var(--color-dark);
  width: 9rem;
}

.void {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-dark);
  color: var(--color-white);
  width: 9rem;
}

.shipped {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-primary);
  color: var(--color-white);
  width: 9rem;
}

.delivered {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--color-green);
  color: var(--color-white);
  width: 9rem;
}

.st {
  display: flex;
  justify-content: center;
}

.channelContainer {
  position: relative;
}

.channel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.exp {
  background-color: var(--color-white);
  // border: 1px solid var(--color-grey);

  &_text {
    font-size: 1rem;
    font-weight: 700;
  }
}

.icons {
  display: flex;
  justify-content: center;
  cursor: pointer;

  &_img {
    display: grid;
    justify-content: right;
    align-items: center;
    width: 100%;

    &__1 {
      display: grid;
      justify-content: right;
      align-items: center;
      width: 100%;
      transform: rotateX(180deg);
    }
  }
}
