@import "../variables/breakpoints";

.wql {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 3rem 5rem 3rem 5rem;
  border-radius: 1rem;
  background-color: var(--color-fash-primary-4);
  background-size: cover;
  gap: 2rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  height: fit-content;

  @media only screen and (max-width: $bp-1000) {
    padding: 2rem;
  }

  &__links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;

    @media only screen and (max-width: $bp-800) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: $bp-600) {
      grid-template-columns: repeat(2, 1fr);
    }

    &_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 3rem;
      gap: 1rem;
      border: 1px solid var(--color-grey);
      border-radius: 1rem;
      box-sizing: border-box;
      width: 100%;
      height: 10rem;
      justify-content: center;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        border: 2px solid var(--color-fash-primary-3);
        color: var(--color-primary);
        transform: translateX(3px) translateY(3px);
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
        background-color: var(--color-fash-primary-4);
      }
    }
  }
}
