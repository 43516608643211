@import "../variables/breakpoints";

.upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @media only screen and (max-width: $bp-600) {
    margin-top: 4rem;
    flex-direction: column;
  }

  &__title {
    width: 38rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_shape {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--default-font-h2);
      font-weight: 700;
      background-color: var(--color-fash-primary-4);
      width: 9rem;
      height: 9rem;
      border-radius: 9rem;
      margin-bottom: 2rem;
    }
  }
}

.main {
  &__nav {
    @media only screen and (max-width: $bp-600) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & > :not(:last-child) {
      margin-bottom: 2rem;
    }

    &_item {
      width: 38rem;
      border: 1px solid var(--color-fash-primary-3);
      border-radius: 1rem;
      padding: 1.9rem 1rem;
      display: grid;
      grid-template-columns: 1fr 8fr 1fr;
      gap: 1rem;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        border: 2px solid var(--color-fash-primary-3);
        color: var(--color-dark);
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
        background-color: var(--color-fash-primary-4);
      }

      &__icon {
        font-size: 2.5rem;
        transition: all 0.2s;
      }

      &__icon2 {
        font-size: 2.5rem;
        transform: rotateZ(90deg);
        transition: all 0.2s;
      }
    }
  }
}

.sub {
  &__nav {
    @media only screen and (max-width: $bp-600) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    & > :not(:last-child) {
      margin-bottom: 2rem;
    }

    &_item {
      width: 36rem;
      border: 1px solid var(--color-dark);
      border-radius: 1rem;
      padding: 1.9rem 1rem;
      display: grid;
      grid-template-columns: 1fr 8fr 1fr;
      gap: 1rem;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;
      margin-left: 2rem;

      &:hover {
        border: 2px solid var(--color-dark);
        // color: var(--color-primary);
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
        background-color: var(--color-light-gry);
        font-weight: 700;
      }

      &__icon {
        font-size: 2.5rem;
      }
    }
  }
}

.bus {
  margin-top: 1rem;
  width: 40rem;

  &__head {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 2rem;

    & > :not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__edit {
    display: flex;
    flex-direction: column;
    font-size: 2.5rem;
    align-items: center;
    cursor: pointer;

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }

    &:hover {
      color: var(--color-fash-primary-3);
    }
  }

  &__details {
    & > :not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__img {
    display: flex;
    justify-content: center;
  }

  &__logo {
    height: 7rem;
  }
}

.sec {
  &__img {
    width: 25rem;
    margin-bottom: 3rem;

    &_contain {
      display: flex;
      justify-content: center;
    }
  }

  &__center {
    width: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.am {
  &__img {
    height: 10rem;
    border-radius: 10rem;

    &_contain {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12rem;
      height: 12rem;
      border-radius: 12rem;
      background-color: var(--color-primary-7);
    }
  }

  &__btn {
    display: flex;
    gap: 2rem;
  }
}

.if {
  &__img {
    height: 22rem;
  }

  &__share {
    display: flex;
    font-size: var(--default-font-small);

    &_1 {
      background-color: var(--color-fash-primary-2);
      width: 28rem;
      padding: 1.5rem 2rem;
      border-radius: 2rem 0 0 2rem;
      word-wrap: break-word;
      color: var(--color-dark);

      span {
        color: var(--color-primary);
      }
    }

    &_2 {
      background-color: var(--color-fash-primary-3);
      color: var(--color-white);
      width: 10rem;
      padding: 1.5rem 2rem;
      border-radius: 0 2rem 2rem 0;
      word-wrap: break-word;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      cursor: pointer;
    }
  }
}

.logout {
  display: flex;
  gap: 2rem;
}

.cs {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__img {
    height: 23rem;
  }

  &__menu {
    margin-top: 3rem;

    & > :not(:last-child) {
      margin-bottom: 2rem;
    }

    &_main {
      display: flex;
      padding: 2.5rem 2rem;
      justify-content: space-between;
      align-items: center;
      font-size: 3rem;
      width: 42rem;
      border-radius: 1.5rem;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        filter: brightness(1.05);
        border: 1px solid var(--color-primary);
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15);
      }

      &__faq {
        background-color: var(--color-primary-3);
      }

      &__send {
        background-color: var(--color-primary-7);
      }

      &__speak {
        background-color: var(--color-primary-8);
      }
    }
  }

  &__dials {
    display: flex;
    gap: 2rem;
    align-items: center;
    font-size: 3rem;
    margin-bottom: 2rem;
    cursor: pointer;
  }

  &__socials {
    display: flex;
    font-size: 3rem;
    justify-content: center;
    color: var(--color-primary);

    & > :not(:last-child) {
      margin-right: 2rem;
    }

    & > * {
      cursor: pointer;

      &:hover {
        color: var(--color-primary-4);
      }
    }
  }
}

.faq {
  width: 42rem;

  &__line {
    border-top: 1px solid var(--color-primary);
    margin: 1rem 0;
  }
}

.pin {
  width: 38rem;

  & > :nth-child(3) {
    margin-left: 4rem;
  }
}

.iconfig {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__menu {
    margin-top: 3rem;
    transition: all 2s;

    & > :not(:last-child) {
      margin-bottom: 2rem;
    }

    &_main {
      display: flex;
      padding: 2.5rem 2rem;
      justify-content: space-between;
      align-items: center;
      font-size: 3rem;
      width: 42rem;
      background-color: var(--color-primary-7);
      border-radius: 1.5rem;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        filter: brightness(1.05);
        border: 1px solid var(--color-primary);
        box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15);
      }
    }
  }

  &__settings {
    padding: 2rem 1.8rem;
    animation: translateDown 0.5s;

    &_feature {
      display: flex;
      justify-content: space-between;
      gap: 2rem;

      &:not(:last-child) {
        margin: 2rem 0;
      }

      &_text {
        width: 80%;
        align-self: center;
      }
    }
  }

  &__beside {
    display: flex;
    justify-content: space-between;
    width: 38rem;
    flex-wrap: wrap;
  }
}

.error {
  font-size: var(--default-font-small);
  color: var(--color-error);
  padding-left: 2rem;
  font-weight: 400;
  margin: 1rem 0;
  width: 38rem;
}

@keyframes translateDown {
  0% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.kyc {
  &__list {
    display: flex;
    align-items: center;
    width: 38rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--color-grey);
    margin-bottom: 2rem;

    &__icon {
      font-size: 4rem;
      color: var(--color-green);
      background-color: var(--color-green-3);
      border-radius: 5rem;
      padding: 0.5rem;
      box-sizing: border-box;
      margin-right: 1rem;
    }

    span {
      width: 33rem;
      word-wrap: break-word;
    }
  }
}

.bicContainer {
  display: flex;
  justify-content: center;
  max-width: 36rem;

  &__bic {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 36rem;
  }
}

.configDetails {
  display: grid;
  grid-template-columns: 55% 35%;
  max-width: 40rem;
  justify-content: center;
  justify-self: center;
  margin-bottom: 2rem;
  gap: 2rem;
  padding: 0 1rem;

  &_btn {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

.repeat {
  display: flex;
  justify-content: space-between;
  width: 38rem;
}

.cards {
  display: flex;
  width: 38rem;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-grey);
  justify-content: space-between;

  img {
    height: 3rem;
    margin-right: 3rem;
  }

  &__beside {
    display: flex;
    align-items: center;
  }

  &__delete {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--color-error);
    cursor: pointer;
    font-size: 3rem;

    &:hover {
      font-weight: 700;
    }

    p {
      font-size: 1.4rem;
      color: var(--color-deep-red);
    }
  }
}

.paystackButton {
  width: 38rem;
  border-radius: 1rem;
  background-color: var(--color-dark);
  color: var(--color-white);
  font-weight: 700;
  box-sizing: border-box;
  padding: 1.9rem 2.2rem;
  justify-content: center;
  margin-top: 2rem;

  &_container {
    display: flex;
    justify-content: center;
  }
}

.subscription {
  width: 42rem;

  &__planSelect {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    box-sizing: border-box;
    font-size: var(--default-font-small);
    width: 100%;
    border-radius: 1rem;

    margin: 1rem 0;

    margin-bottom: 3rem;
    cursor: pointer;

    & > * {
      padding: 1rem 0 1rem 0;
      text-align: center;
    }

    &__active {
      background-color: var(--color-primary);
      color: var(--color-white);
      transition: all 0.4s;
      border: 1px solid var(--color-primary);
      font-weight: 700;

      &__1 {
        border-radius: 1rem 0 0 1rem;
      }

      &__2 {
        border-radius: 0 1rem 1rem 0;
      }
    }

    &__inactive {
      border: 1px solid var(--color-primary);
      transition: all 0.4s;
      border: 1px solid var(--color-primary);
      color: var(--color-dark);

      &__1 {
        border-radius: 1rem 0 0 1rem;
      }

      &__2 {
        border-radius: 0 1rem 1rem 0;
      }
    }
  }

  &__planDetails {
    &__deselected {
      border-radius: 1rem;
      background-color: var(--color-light);
      margin-bottom: 2rem;
      padding: 2rem;
      border: 1px solid var(--color-grey);
      cursor: pointer;
    }

    &__selected {
      border-radius: 1rem;
      background-color: var(--color-primary-3);
      margin-bottom: 2rem;
      padding: 2rem;
      border: 1px solid var(--color-primary-4);
      cursor: pointer;
    }

    &__item {
      display: grid;
      grid-template-columns: min-content 1fr;
      gap: 1rem;
    }
  }

  &__save {
    border-radius: 1rem;
    background-color: var(--color-light-red);
    color: var(--color-error);
    font-weight: 700;
    padding: 1rem;
    border: 1px solid var(--color-error);
    font-size: var(--default-font-small);
  }

  &__summary {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    &__item {
      background-color: var(--color-primary-3);
      padding: 1rem;
      border-radius: 1rem;
    }
  }

  &__img {
    margin-bottom: 2rem;
    width: max-content;
  }

  &__upgrade {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    &__item {
      background-color: var(--color-primary-3);
      padding: 1rem;
      border-radius: 1rem;
      height: 7.5rem;
      font-weight: 700;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;

      &__active {
        border: 2px solid var(--color-primary);
        border-radius: 1rem;
        padding: 1rem;
        transition: all 0.2s;
      }
    }
  }
}

.ai {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 3rem 5rem 3rem 5rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  height: auto;
  gap: 2rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);

  &__copy {
    display: flex;
    font-size: 3rem;
    color: var(--color-white);
    cursor: pointer;
    background-color: var(--color-dark);
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    align-items: center;
  }

  &__card {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    min-width: 80rem;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 1rem;
    background-color: var(--color-primary-3);
    gap: 1rem;
    scroll-behavior: auto;

    @media only screen and (max-width: $bp-700) {
      height: auto;
      font-size: 2rem;
    }

    &__align {
      display: flex;
      justify-content: space-between;
      max-width: 100%;
      flex-wrap: wrap;
    }
  }
}

.imageUpload {
  height: 10rem;
}

.beside {
  display: flex;
  gap: 4rem;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2rem;
}

.button {
  height: 4rem;
  width: 18rem;
  border: 1px solid #ddd;
  color: var(--color-dark);
  font-weight: normal;
  transition:
    background-color 0.3s,
    color 0.3s;
  cursor: pointer;
  text-align: center;
  line-height: 4rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button.active {
  background-color: var(--color-fash-primary-2);
  color: var(--color-white);
  font-weight: 700;
}

.button.dormant {
  background-color: #e9ecef;
  color: var(--color-dark);
}
