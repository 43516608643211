@import './variables/variables';

.form {
	display: block;

	&__group:not(:last-child) {
		margin-bottom: 1rem;
	}

	&__input {
		font-size: $default-font-size;
		font-family: inherit;
		color: var(--color-dark);
		padding-left: 2rem;
		width: 100%;
		height: 6rem;
		border-radius: 1rem;
		background-color: var(--color-white);
		border: 1px solid var(--color-dark);
		display: block;
		margin-top: 0.5rem;

		&:focus,
		&:focus-visible,
		&:focus-within {
			color: var(--color-dark);
			border: 1px solid $color-primary;
		}

		&_filter {
			font-size: var(--default-font-small);
			font-family: inherit;
			color: var(--color-dark);
			padding-left: 2rem;
			width: 100%;
			height: 4rem;
			border-radius: 1rem;
			background-color: var(--color-white);
			border: 1px solid var(--color-dark);
			display: block;
			margin-top: 0.5rem;

			&:focus,
			&:focus-visible,
			&:focus-within {
				color: var(--color-dark);
				border: 1px solid $color-primary;
			}
		}
	}

	&__label {
		font-size: $default-font-small;
		font-family: inherit;
		padding-left: 2rem;
		box-sizing: border-box;
		width: 38rem;
	}

	&__calendar {
		font-family: inherit;
		font-size: 1.4rem;
		background-color: var(--color-white);
		color: var(--color-dark);
		box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
		border-radius: 1rem;
		border: none;
		width: 25rem;
		height: 27.5rem;
		display: inline-block;
		position: relative;
		box-sizing: border-box;
		padding: 5px;

		&> :nth-child(3) {
			background-color: var(--color-dark);
			left: 1rem;
			top: 1rem;
			border-radius: 0.8rem;

			&:hover {
				background-color: var(--color-primary-4);
			}

			span::before {
				right: -0.3rem;
				top: 0.7rem;
			}
		}

		&> :nth-child(4) {
			background-color: var(--color-dark);
			right: 1rem !important;
			top: 1rem;
			border-radius: 0.8rem;

			&:hover {
				background-color: var(--color-primary-4);
			}

			span::before {
				left: -0.3rem;
				top: 0.7rem;
			}
		}

		&> :nth-child(5) {
			width: 100%;
			font-family: 'Montserrat';
			font-size: 1.4rem;
			background-color: var(--color-light);

			&> :nth-child(1) {
				background-color: var(--color-primary-3);

				&> :nth-child(1) {
					font-size: 1.4rem;
					color: var(--color-primary);
				}

				&> :nth-child(3) {
					font-weight: 700;
					color: var(--color-dark);
					display: grid;
					grid-template-columns: repeat(7, 1fr);
				}
			}

			&> :nth-child(2) {
				&> :nth-child(even) {
					background-color: var(--color-primary-7);
				}

				&>* {
					color: var(--color-dark);
					display: grid;
					grid-template-columns: repeat(7, 1fr);
					height: 3rem;
				}
			}
		}

		&> :nth-child(6) {
			font-family: 'Montserrat';
			font-size: var(--default-font-small);
			position: absolute;
			right: -85px;

			&> :nth-child(1) {
				&>* {
					font-size: var(--default-font-xsmall) !important;
				}
			}
		}
	}
}

// When form is filled

.filled {
	color: var(--color-dark);
}

.error {
	font-size: var(--default-font-small);
	color: var(--color-error);
	// padding-left: 2rem;
	font-weight: 400;
	margin: 1rem 0;
	max-width: 38rem;
}

.note {
	display: flex;
	align-items: center;
	font-size: var(--default-font-small);
	color: var(--color-dark);
	// padding-left: 2rem;
	font-weight: 400;
	margin: 1rem 0;
}

.pageCount {
	font-size: var(--default-font-small);
	// padding-left: 2rem;
	font-weight: 700;
	margin: 1rem 0;
	max-width: 38rem;
}

// Success
.success {
	&__h3 {
		color: var(--color-success);
		font-size: var(--default-font-h3);
		font-weight: 700;
		line-height: 3.5rem;
	}
}

.iconAlign {
	max-height: 6rem;
	position: relative;

	&> :nth-child(2) {
		position: absolute;
		left: 0;
	}
}

.tag {
	background-color: var(--color-primary-3);
	font-size: var(--default-font-small);
	height: 4rem;
	padding: 0.5rem 1rem;
	border-radius: 8px;
	width: 15rem;
	margin-top: 1rem;
	margin-right: 1rem;

	&_close {
		display: block;
		background-color: var(--color-dark);
		color: var(--color-white);
		width: 16px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		border-radius: 50%;
	}
}


// .tags{
//     margin: 20px auto;
//     display: flex;
//     flex-wrap: wrap;
//     overflow: hidden;
//     width: 70%;
//     min-width: 80%;
//     border: 1px rgb(173, 162, 162) solid;
//     border-radius: 5px;
//     color: black;
// }

.tags {
	display: flex;
	flex-wrap: wrap;

	&> :not(:last-child) {
		margin-right: 1rem;
	}
}

// .single-tag{
//     list-style: none;
//     display: flex;
//     align-items: center;
//     margin: 7px 0 7px 14px;
//     padding: 0 10px;
//     padding-right: 5px;
//     border: 1.5px solid #764abc;
//     border-radius: 5px;
//     white-space: nowrap;
//     color: #764abc;
// }

.singleTag {
	display: flex;
	font-size: var(--default-font-small);
	align-items: center;
	background-color: var(--color-primary-3);
	padding: 0.5rem;
	border-radius: 0.5rem;
	cursor: pointer;
	margin-bottom: 0.5rem;

	&> :not(:last-child) {
		margin-right: 0.5rem;
	}


	&:hover {
		color: var(--color-primary);
	}

}

.suggestions {

	padding: 0.5rem;
	font-size: var(--default-font-small);
	cursor: pointer;
	background-color: var(--color-dark);
	border-radius: 0.5rem;
	color: var(--color-white);

	&__all {
		display: flex;
		flex-wrap: wrap;

		&>:not(:last-child) {
			margin-right: 0.5rem;
		}
	}
}

.dateInput {
	width: 100%;
}

.otpInput {
	display: block;

	&__title {
		display: block;
		margin-bottom: 0.5rem;
		margin-left: 2rem;
		font-size: var(--default-font-small);
	}
}