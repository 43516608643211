@import "../variables/breakpoints";

.ai {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 3rem 5rem 3rem 5rem;
  border-radius: 1rem;
  background-color: var(--color-white);
  height: auto;
  gap: 2rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);

  @media only screen and (max-width: $bp-900) {
    height: auto;
    padding: 3rem;
  }

  &__line {
    border-top: 1px solid var(--color-grey-2);
  }

  &__copy {
    display: flex;
    font-size: 3rem;
    color: var(--color-white);
    cursor: pointer;
    background-color: var(--color-dark);
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    align-items: center;

    & > :not(:last-child) {
      margin-right: 2rem;
    }
  }

  &__bank {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 2rem;
    border-radius: 1rem;
    background-color: var(--color-fash-primary-4);
    gap: 1rem;

    @media only screen and (max-width: $bp-900) {
      height: auto;
    }
  }
}
