@import '../variables/breakpoints';

.reminder {
	width: 100%;
	border-radius: 2rem;
	background-color: var(--color-white);
	box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
	box-sizing: border-box;
	padding: 2rem 2rem;

	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 2rem;

		&__text {
			padding: 0;
			margin: 0;
			font-size: var(--default-font-size);
			font-weight: 700;
		}

		&__icon {
			font-size: 2.4rem;
			& > :not(:last-child) {
				margin-right: 1rem;
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
	}

	&__list {
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		font-size: var(--default-font-small);
		cursor: pointer;
		&:not(:last-child) {
			margin-bottom: 2rem;
			@media only screen and (max-width: $bp-medium) {
				margin-bottom: 4rem;
			}
		}
		&__check {
			width: 2rem;
			height: 2rem;
			border: 2px solid var(--color-primary);
			cursor: pointer;
			margin-right: 1rem;
		}

		&__details {
			margin-right: 1rem;
		}

		&__time {
			color: var(--color-primary);
		}
	}
}
