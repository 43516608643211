@import "../variables/breakpoints";
.modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: $bp-700) {
    display: grid;
    grid-template-columns: min-content max-content;
    gap: 1rem;
  }

  @media only screen and (max-width: $bp-500) {
    grid-template-columns: min-content max-content;
    gap: 1rem;
  }

  & > :nth-child(2) {
    @media only screen and (max-width: $bp-500) {
      grid-row: 1/2;
      grid-column: 1/3;
      justify-self: center;
    }
  }

  & > :last-child {
    @media only screen and (max-width: $bp-700) {
      grid-column: 2/3;
      justify-self: end;
    }
  }

  &__btns {
    @media only screen and (max-width: $bp-700) {
      display: flex;
      gap: 2rem;
    }
    @media only screen and (max-width: $bp-500) {
      display: flex;
      flex-direction: column;
      gap: 0;
    }
  }
}

.customer {
  &__num {
    width: 18rem;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 6rem;
    border-radius: 1rem;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  }

  &__search {
    position: relative;
    top: -1.3rem;
    @media only screen and (max-width: $bp-900) {
      top: -0.5rem;
    }
  }

  &__list {
    margin-top: 2rem;
    display: flex;
    position: relative;
  }

  &__avatar {
    width: 9rem;

    &_container {
      width: 10rem;
      height: 10rem;
      border-radius: 7rem;
      border: 1px solid var(--color-primary);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 3rem 0;
    }
  }
}

//Table styles start

.table {
  width: 100%;
  max-width: 100%;
  border: none;
  border-collapse: collapse;
  box-sizing: border-box;
  text-align: left;
  font-size: var(--default-font-small);

  tr {
    display: grid;
    grid-template-columns: 25% 15% 28% 15% 17%;
    padding: 0 1rem;
    align-items: center;

    @media only screen and (max-width: $bp-600) {
      grid-template-columns: 1fr;
    }
  }

  th,
  td {
    padding: 2.2rem 2rem;
  }

  th {
    font-weight: 700;
  }

  td {
    cursor: pointer;
  }

  tbody {
    & > :not(:last-child) {
      margin-bottom: 1.5rem;
    }

    tr {
      background-color: var(--color-primary-3);
      border-radius: 1.3rem;

      &:nth-child(even) {
        background-color: var(--color-primary-7);
        border-radius: 1.3rem;
      }
    }
  }
}

.minMenu {
  color: var(--color-primary);
  cursor: pointer;
  font-size: 2rem;
}
//Table styles end

.gender {
  display: flex;
  justify-content: space-between;
}

.customerMenu {
  background-color: var(--color-white);
  font-size: var(--default-font-small);
  position: absolute;
  right: 7rem;
  top: 8rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-grey);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  color: var(--color-primary);
  box-sizing: border-box;
  width: 15rem;

  ul {
    margin: 1.2rem 1.7rem;

    & > :not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  li {
    list-style: none;
    cursor: pointer;

    &:hover {
      color: var(--color-primary-4);
      font-weight: 700;
    }
  }
}

// View Container
.view {
  position: relative;
  &_menu {
    color: var(--color-primary);
    cursor: pointer;
    font-size: 3rem;
    position: absolute;
    left: 20.5rem;
    top: 6rem;
  }

  &_customerMenu {
    right: -20rem;
    top: 6rem;
  }

  &_content {
    width: 38rem;
    margin-top: 3rem;
  }
}

//Add Customers
.midBtn {
  display: flex;
  justify-content: center;
}

//checkbox
.checks {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;

  &_group {
    align-self: center;
  }
}

.error {
  font-size: var(--default-font-small);
  color: var(--color-error);
  padding-left: 2rem;
  font-weight: 400;
  margin: 1rem 0;
}

.checks {
  width: 38rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.measurement {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  align-items: center;
  margin: 2rem 0;
  width: 38rem;
  &_delete {
    align-self: flex-end;
    font-size: 4.6rem;
  }
}

.dropZone {
  display: flex;
  width: 38rem;
  justify-content: center;
  align-items: center;
  background-size: contain;
}
