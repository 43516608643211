@import "../variables/breakpoints";

.ql {
  width: 100%;
  border-radius: 2rem;
  background-color: var(--color-fash-primary-4);
  color: var(--color-dark);
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
  padding: 3.5rem 2rem;

  &__title {
    padding: 0;
    margin: 0;
    font-size: var(--default-font-size);
    font-weight: 700;
    margin-bottom: 3rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    box-sizing: border-box;
    margin: 2rem 0 2rem 0;

    & > :not(:last-child) {
      margin-bottom: 2rem;
      @media only screen and (max-width: $bp-medium) {
        margin-bottom: 4rem;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &:hover {
      color: var(--color-fash-primary-3);
      font-weight: 700;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  &__icon {
    font-size: 2.2rem;
  }
}
.reference {
  display: flex;
  background-color: var(--color-fash-black);
  border-radius: 1rem;
  padding: 2rem;
  color: var(--color-white);
  gap: 2rem;
  width: 100%;
  margin-bottom: 2rem;

  @media only screen and (max-width: $bp-700) {
    flex-direction: column;
    width: 100%;
  }

  &__copy {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    color: var(--color-fash-black);
    cursor: pointer;
    border-radius: 0.5rem;
    align-items: center;
    background-color: var(--color-white);
    padding: 1rem;
    width: 100%;
    gap: 2rem;

    &:hover {
      transition: 0.5s;
      background-color: var(--color-fash-primary-4);
    }

    &__icon {
      font-size: 3.2rem;
    }
  }

  &__link {
    width: 100%;
  }
}
