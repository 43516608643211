@import "../variables/breakpoints";

.bi {
  &__content {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media only screen and (max-width: $bp-600) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__income {
    background-color: var(--color-green);
    color: var(--color-white);
    height: 12.5rem;
  }

  &__profit {
    background-color: var(--color-yellow);
    height: 12.5rem;
  }

  &__credit {
    background-color: var(--color-primary-3);
    height: 12.5rem;
  }

  &__expenses {
    background-color: var(--color-light-red);
    height: 12.5rem;
  }

  &__sales {
    background-color: var(--color-primary-7);
    height: 12.5rem;
  }

  &__health {
    background-color: var(--color-green-2);
    height: 12.5rem;
  }

  &__info {
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
  }

  &__sideCards {
    display: grid;
    grid-template-columns: repeat(2, 49%);
    gap: 3rem;
    box-sizing: border-box;
    margin-top: 6rem;

    @media only screen and (max-width: $bp-900) {
      grid-template-columns: 80%;
      justify-content: center;
    }

    @media only screen and (max-width: $bp-500) {
      grid-template-columns: 100%;
    }
  }

  &__mainCards {
    height: 45rem;
    width: 100%;
    box-sizing: border-box;
    padding: 4rem 2rem 2rem 2rem;
    background-color: var(--color-fash-primary-3);
    border-radius: 2rem;

    &_b {
      height: fit-content;
      min-height: 50rem;
      width: 100%;
      box-sizing: border-box;
      padding: 2rem;
      border-radius: 2rem;
      border: 2px solid var(--color-fash-primary-3);
      position: relative;
      table {
        width: 100%;
        tbody {
          & > :not(:last-child) {
            margin-bottom: 1rem;
          }
        }

        tr {
          display: grid;
          grid-template-columns: 1fr 2fr;

          & > :last-child {
            font-weight: 700;
            text-align: right;
          }
        }
      }
    }

    table {
      width: 100%;
      tbody {
        & > :not(:last-child) {
          margin-bottom: 1rem;
        }
      }

      tr {
        display: grid;
        grid-template-columns: 1fr 13fr 2fr;

        & > :last-child {
          font-weight: 700;
        }
      }
    }
  }
}

.card {
  &_top {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;

    @media only screen and (max-width: $bp-600) {
      font-size: var(--default-font-small);
    }

    &_icon {
      display: flex;
      & > :first-child {
        margin-right: 1rem;
      }
    }

    &_filter {
      display: flex;
      & > :first-child {
        margin-right: 3rem;
      }
    }

    &_pie {
      width: 100%;
    }
  }

  &_bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    & > :not(:last-child) {
      margin-right: 1rem;
    }

    &_content {
      display: flex;
      align-items: center;
      font-size: var(--default-font-small);

      img {
        height: 1.5rem;
        margin-right: 1rem;
      }
    }
  }
}

.modalTable {
  table {
    width: 40rem;
    tbody {
      & > :not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    tr {
      display: grid;
      grid-template-columns: 7fr 3fr;

      & > :last-child {
        font-weight: 700;
      }
    }
  }
}

.modalInfo {
  font-size: 3rem;
  color: var(--color-primary);
  position: relative;
  left: 20rem;
  margin-top: 3rem;
}

.terms {
  width: 40rem;
  span {
    font-weight: 700;
    color: var(--color-primary);
  }
}
