@import "../variables/breakpoints";

.filter {
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 3rem;
  background-color: var(--color-white);
  border-radius: 1rem;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
  margin-bottom: 2rem;
  height: fit-content;

  &_button {
    display: flex;
    align-items: center;
    color: var(--color-dark);
    padding: 0 1rem;
    cursor: pointer;
    font-size: var(--default-font-small);

    &:hover {
      color: var(--color-dark);
    }

    &_icon {
      font-size: 3rem;
      margin-right: 1rem;
    }
  }

  &_line {
    margin-top: 1rem;
    border-top: 1px solid var(--color-fash-primary);
  }

  &_content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2rem;
    gap: 1rem;

    & > :not(last-child) {
      margin-right: 1rem;

      @media only screen and (max-width: $bp-700) {
        margin-bottom: 2rem;
      }
    }
  }
}
