@import "../variables/breakpoints";

.main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  background-color: var(--color-fash-primary-4);
  padding: 2rem;
  margin: 2rem;

  @media only screen and (max-width: $bp-800) {
    padding: 2rem 1rem;
    flex-direction: column;
    align-items: center;
  }

  &__red {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: var(--color-error);
    color: var(--color-white);
    padding: 2rem;
    margin-bottom: 2rem;

    @media only screen and (max-width: $bp-800) {
      padding: 2rem 1rem;
      flex-direction: column;
      align-items: center;
    }
  }
}

.banner {
  position: absolute;
  bottom: 2rem;
  width: 90%;
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 1rem;
  background-color: var(--color-white);

  &__beside {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

    @media only screen and (max-width: $bp-1300) {
      grid-template-columns: 1fr;
    }
  }

  &__icon {
    height: 1.7rem;
  }
}
