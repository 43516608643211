@import "../variables/breakpoints";

.modal {
  &__img {
    height: 7.5rem;
    margin-bottom: 3rem;
  }
}

.main {
  &_nav {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding: 1rem;
    border-top: solid 1px var(--color-primary);
    background-color: var(--color-dark-2);
    // background-color: var(--color-fash-primary-2);
    box-shadow: 1px 5px 10px -5px rgba(0, 0, 0, 0.3);
    color: var(--color-white);
    // border-bottom: solid 1px var(--color-dark);
    // border-radius: 1rem 1rem 0 0;

    & > :not(:last-child) {
      margin-right: 6rem;
    }

    &_group {
      position: relative;
    }

    &_more {
      position: absolute;
      right: 0;
      top: 75%;
      background-color: var(--color-dark-2);
      padding: 1.5rem;
      border-radius: 1rem;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
      z-index: 5;

      & > :not(last-child) {
        margin-bottom: 1rem;
      }
    }

    &_item {
      cursor: pointer;
      padding: 1rem 0;
      box-sizing: border-box;
      font-size: var(--default-font-small);

      // @media only screen and (max-width: $bp-600) {
      // 	width: 47%;
      // }

      &:hover,
      &:focus,
      &:focus-visible,
      &:focus-within {
        font-weight: 700;
        color: var(--color-white);
        border-bottom: solid 2px var(--color-white);
      }

      &_active {
        cursor: pointer;
        padding: 1rem 0;
        box-sizing: border-box;
        font-size: var(--default-font-small);
        font-weight: 700;
        color: var(--color-primary-3);
        border-bottom: solid 2px var(--color-primary-3);

        // @media only screen and (max-width: $bp-600) {
        // 	width: 47%;
        // }

        &:hover,
        &:focus,
        &:focus-visible,
        &:focus-within {
          font-weight: 700;
          color: var(--color-primary-3);
          border-bottom: solid 2px var(--color-primary-3);
        }
      }
    }
  }

  &__content {
    margin-top: 5rem;
  }

  &__metrics {
    box-sizing: border-box;
    padding: 3.5rem 2rem;
    width: 100%;
    display: grid;
    box-sizing: border-box;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;

    @media only screen and (max-width: $bp-600) {
      grid-template-columns: repeat(2, 50%);
    }

    &_active {
      background-color: var(--color-green);
      color: var(--color-white);
    }

    &_suspended {
      background-color: var(--color-yellow);
    }

    &_terminated {
      background-color: var(--color-error);
      color: var(--color-white);
    }
  }

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.createLinks {
  display: flex;
  justify-content: center;

  &_1 {
    background-color: var(--color-white);
    padding: 3rem;
    border-radius: 2rem;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);

    width: 60%;

    @media only screen and (max-width: $bp-800) {
      width: 100%;
    }
  }

  &_2 {
    background-color: var(--color-dark-2);
    padding: 3rem;
    border-radius: 2rem;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 7rem;
    }
  }

  &_mini {
    background-color: var(--color-white);
    padding: 2rem;
    margin: 1rem 3rem;
    border-radius: 2rem;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    width: 100%;
  }
}

.beside {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;

  @media only screen and (max-width: $bp-500) {
    grid-template-columns: 100%;
  }
}

.status {
  &__pending {
    color: var(--color-yellow-3);
  }

  &__paid {
    color: var(--color-green);
  }

  &__failed {
    color: var(--color-error);
  }
}

.active {
  font-weight: 700;
  color: var(--color-green);
}

.deactivated {
  font-weight: 700;
  color: var(--color-error);
}
