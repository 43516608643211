@import '../variables/variables';

//FORM side

.form {
	display: block;

	&__group {
		display: flex;
		flex-direction: column;
		align-items: center;
		&:not(:last-child) {
			margin-bottom: 1rem;
		}
	}

	&__pin__input {
		font-size: $default-font-size;
		font-family: inherit;
		color: $color-grey;
		width: 6rem;
		height: 6rem;
		border-radius: 1rem;
		background-color: $color-white;
		border: 1px solid $color-dark;
		display: block;
		text-align: center;

		&:focus,
		&:focus-visible,
		&:focus-within {
			color: $color-dark;
			border: 1px solid $color-primary;
		}
	}

	&__input {
		font-size: $default-font-size;
		font-family: inherit;
		color: $color-grey;
		padding-left: 2rem;
		width: 38rem;
		height: 6rem;
		border-radius: 1rem;
		background-color: $color-white;
		border: 1px solid $color-dark;
		display: block;
		box-sizing: border-box;

		&:focus,
		&:focus-visible,
		&:focus-within {
			color: $color-dark;
			border: 1px solid $color-primary;
		}
	}

	&__label {
		font-size: $default-font-small;
		font-family: inherit;
		padding-left: 2rem;
	}

	&__upload {
		font-size: $default-font-size;
		font-family: inherit;
		font-weight: 700;
		text-align: center;
		color: $color-dark;

		width: 38rem;
		height: 6rem;
		border-radius: 1rem;
		background-color: $color-primary-3;
		border: 1px dashed $color-dark;
		display: block;
		box-sizing: border-box;
		cursor: pointer;

		&:focus,
		&:focus-visible,
		&:focus-within {
			color: $color-dark;
			border: 1px solid $color-primary;
		}
	}
}

// When form is filled

.filled {
	color: $color-dark;
}

//otp styles
.otp__group {
	display: flex;
	gap: 2rem;
	margin-top: 1rem;
}

// button style
button {
	font-family: 'Montserrat', sans-serif;
	font-size: $default-font-size;
	display: flex;
	background-color: transparent;
	border: none;
}

.btn {
	padding: 1.9rem 2.2rem;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	font-weight: 700;
	border-radius: 1rem;
	cursor: pointer;

	&__outline {
		border: 1px solid $color-dark;
		margin-right: 2.3rem;
		color: $color-dark;

		&:hover {
			border: 1px solid $color-primary;
			color: $color-primary;
		}
	}

	&__default {
		background-color: $color-primary-2;
		color: $color-white;

		&:hover {
			background-color: $color-primary-4;
		}
	}

	&__default_38 {
		background-color: $color-primary-2;
		color: $color-white;
		width: 38rem;

		&:hover {
			background-color: $color-primary-4;
		}
	}

	&__icon {
		height: 2.2rem;
		margin-right: 1rem;
	}

	&__form {
		background-color: $color-primary-2;
		color: $color-white;
		width: 100%;
		margin-top: 2rem;

		&:hover {
			background-color: $color-primary-4;
		}
	}

	&__text {
		color: $color-primary-2;
		text-decoration: underline;
		position: relative;
		left: 29rem;

		&:hover {
			color: $color-primary-4;
		}

		&::after {
			content: '';
			background: url('../../svg/arrowDown.svg');
			background-size: contain;
			background-repeat: no-repeat;

			position: absolute;
			height: 100%;
			width: 1.6rem;
			right: 0;
			top: 2.5rem;
		}
	}
}

//Dropdown or Select
.select {
	display: flex;
	width: 38rem;
	flex-direction: column;
	margin-top: 3rem;

	&__options {
		padding: 1.5rem 2rem;
		cursor: pointer;
		width: 100%;

		&:hover {
			background-color: $color-primary;
			color: $color-white;
		}

		&_selected {
			font-size: $default-font-size;
			font-family: inherit;
			color: $color-dark;
			padding-left: 2rem;
			width: 38rem;
			height: 6rem;
			border-radius: 1rem;
			background-color: $color-white;
			border: 1px solid $color-dark;
			display: block;
			cursor: pointer;
			margin-bottom: 1rem;
			position: relative;
			order: 0;
			box-sizing: border-box;

			& > * {
				position: relative;
				top: 1.8rem;
			}
		}

		&_selected::after {
			content: '';
			background: url('../../svg/arrowDown.svg');
			background-size: contain;
			background-repeat: no-repeat;

			position: absolute;
			height: 100%;
			width: 1.6rem;
			right: 2rem;
			top: 2.5rem;

			transition: all 0.4s;
		}

		&_active + &_selected::after {
			transform: rotateX(180deg);
			top: -2.5rem;
		}

		&_container {
			color: $color-dark;
			width: 100%;
			max-height: 27rem;
			border-radius: 1rem;
			background-color: $color-white;
			border: 1px solid $color-dark;
			order: 1;
			overflow-y: scroll;
			margin-bottom: 2rem;
			transition: all 0.4s;
			box-sizing: border-box;

			&::-webkit-scrollbar {
				width: 1rem;
				background-color: $color-white;
				border-radius: 0 1rem 1rem 0;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $color-grey;
				border-radius: 1rem;
			}
		}

		&_active {
			opacity: 1;
			max-height: 27rem;
			overflow-y: scroll;
		}

		&_label {
			cursor: pointer;
		}
	}
}

.error {
	font-size: var(--default-font-small);
	color: var(--color-error);
	padding-left: 2rem;
	font-weight: 400;
	margin: 1rem 0;
}

.uploadLogo {
	height: 10rem;
	border-radius: 2rem;
	border: 3px solid var(--color-primary);
	margin: 2rem 0;
	padding: 0.5rem;
}
