@import './variables/breakpoints';

.label {
	font-size: var(--default-font-small);
	font-family: inherit;
	padding-left: 2rem;
	margin-bottom: 1rem;

	&__wide {
		padding-left: 4.5rem;
	}
}


.numberedPage {
	width: 3rem;
	height: 3rem;
	font-size: var(--default-font-small);
	border-radius: 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: var(--color-white);

	&:hover {
		border: 1px solid #161715;
		color: var(--color-dark);
	}


	&:disabled {
		color: var(--color-grey);
		border: 1px solid var(--color-grey);

	}
}

.numberPageActive {
	border: 1px solid #161715;
	color: var(--color-dark);
	box-shadow: 0px 0px 7px rgba(32, 45, 40, 0.5);
}

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 2rem;
	width: 100%;

	&> :not(:last-child) {
		margin-right: 1rem;
	}
}