.ra {
	display: flex;
	flex-direction: column;

	&__main {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: var(--color-white);
		padding: 3rem 2rem;
		height: 48rem;
		overflow-y: scroll;

		&::-webkit-scrollbar {
			width: 1rem;
			background-color: var(--color-white);
			border-radius: 0 3rem 3rem 0;
		}

		&::-webkit-scrollbar-thumb {
			background-color: var(--color-grey);
			border-radius: 3rem;
		}
	}

	&__content {
		width: 100%;
		& > :not(:last-child) {
			margin-bottom: 2rem;
		}

		&_list {
			display: grid;
			grid-template-columns: 3rem 1fr 0.3fr;
			gap: 2rem;
			align-items: center;
		}
		&_details {
		}

		&_icon {
			content: '';
			background-color: var(--color-green);
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 1.5rem;
			justify-self: center;
		}
	}
}

.debit {
	background-color: var(--color-error);
}
