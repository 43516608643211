@import "../variables/breakpoints";

.container {
  display: flex;
  justify-content: center;
  width: 100%;

  &_form {
    // display: flex;
    // justify-content: space-between;
    width: 60%;
    box-sizing: border-box;
    border: solid var(--color-fash-primary-3);
    padding: 3rem;
    margin-top: 4rem;
    align-items: center;
    border-radius: 1rem;

    @media only screen and (max-width: $bp-800) {
      width: 80%;
    }

    @media only screen and (max-width: $bp-600) {
      width: 100%;
    }

    &__domain {
      display: flex;
      align-items: flex-end;
      gap: 1rem;
    }

    &__input {
      width: 50rem;

      @media only screen and (max-width: $bp-1000) {
        width: 40rem;
      }

      @media only screen and (max-width: $bp-800) {
        width: 32rem;
      }

      @media only screen and (max-width: $bp-600) {
        width: 22rem;
      }
    }
  }
}

.upperBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.checks {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 2rem 0;

  &_group {
    align-self: center;
  }
}

.dropZone {
  display: flex;
  width: 38rem;
  justify-content: center;
  align-items: center;
  background-size: contain;
}

.sizesRow {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  /* Add some space between rows */
}

.checks {
  width: 38rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.price {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding-bottom: 1rem;
  gap: 2rem;
}

.error {
  color: var(--color-error);
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  width: 100%;
  border: 1px solid var(--color-grey);
  padding: 1rem;
  border-radius: 1rem;
  margin-bottom: 2rem;
  cursor: pointer;



  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__item {
    background-color: var(--color-grey);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2.4rem;

    &__large {
      grid-row: span 2;
    }

    &__half {
      grid-row: span 1;
    }
  }

}