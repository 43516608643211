@import "../variables/breakpoints";

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media only screen and (max-width: $bp-1000) {
    grid-template-columns: 1fr;
  }

  &_card {
    color: var(--color-dark);
    background-color: var(--color-light);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    border-radius: 2rem;
    margin: 2rem;
    padding: 2rem;
  }

  &_item {
    display: flex;
    justify-content: space-between;
    gap: 4rem;

    &__title {
      font-size: medium;
      width: 100%;
      margin: 0.5rem;
    }
    &__text {
      font-size: large;
      text-align: left;
      margin: 0.5rem;
      width: 100%;
    }
  }
}
